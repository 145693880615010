
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import Admin from './Components/Admin';
import Admin2 from './Components/Admin2';
import MyNumber from './Components/MyNumber';
import Loader from './Components/Loader';
import NotFount from './Components/NotFount';
import Bet from './Components/Bet';
import Login from './Components/Login';
import Market from './Components/Market';
import AccountList from './Components/AccountList';
import InsertUser from './Components/InsertUser';
import AccountStatment from './Components/AccountStatment';
import CurrentBet from './Components/CurrentBet';
import Mybets from './Components/Mybets';
import AccountStatmentUser from './Components/AccountStatmentUser';
import CurrentbetAdmin from './Components/CurrentbetAdmin';
import GameReport from './Components/GameReport';
import ProtectedRoute from './context/ProtectedRoute';

function App() {
  return (
    <Router>
      <div>
        {/* Define Routes */}
        <Routes>
          <Route path="*" element={<NotFount />} />
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/admin" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
          <Route path="/admin/insertUser" element={<ProtectedRoute><InsertUser /></ProtectedRoute>} />
          <Route path="/admin2" element={<ProtectedRoute><Admin2 /></ProtectedRoute>} />
          <Route path="/admin/market" element={<ProtectedRoute><Market /></ProtectedRoute>} />
          <Route path="/admin/accountList" element={<ProtectedRoute><AccountList /></ProtectedRoute>} />
          <Route path="/admin/:id" element={<Login />} />
          <Route path="/kalyanMorning" element={<Bet />} />
          <Route path="/kalyan" element={<Bet />} />
          <Route path="/kalyanNight" element={<Bet />} />
          <Route path="/current-bet" element={<CurrentBet />} />
          <Route path="/admin/accountStatment" element={<ProtectedRoute><AccountStatment /></ProtectedRoute>} />
          <Route path="/my-bets" element={<Mybets />} />
          <Route path="/accountStatment" element={<AccountStatmentUser />} />
          <Route path="/admin/reports/currentbets" element={<ProtectedRoute><CurrentbetAdmin /></ProtectedRoute>} />
          <Route path="/admin/reports/gamereport" element={<ProtectedRoute><GameReport /></ProtectedRoute>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
