import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import React from 'react'
import { useState } from 'react'
import matka from '../Images/matka.png'
import { Link } from 'react-router-dom';



export default function Header() {
  const { setUser,user } = useContext(UserContext);
  const [Clicked, setisClicked] = useState(false)
  function btnClickHandler() {
    setisClicked(!Clicked)
  }
  function reMoveBtn(){
    
  }
  
  return (
    <div>
      <>
        <meta charSet="UTF-8" />
        <link rel="icon" type="image/png" href="/fav-icon.png" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css"
        />
        <link
          rel="stylesheet"
          href="https://versionobj.ecoassetsservice.com/v13/static/front/css/flipclock.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://versionobj.ecoassetsservice.com/v13/static/front/css/style.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://versionobj.ecoassetsservice.com/v13/static/front/css/responsive.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://versionobj.ecoassetsservice.com/v13/static/front/css/custom.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://sitethemedata.com/sitethemes/grandexch.com/front/theme.css"
        />
        <div id="root">



          <section className="header" style={{marginBottom:"4px",position:"relative"}} onClick={reMoveBtn}>
            <div className="header-top" style={{ background: "#ff00a2" }}>
              <div className="logo-header">
                <a className="d-xl-none" href="/">
                  <i className="fas fa-home me-1" />
                </a>
                <Link to={"/home"}>
                  <div className="f-pti">
                    <h1 style={{ color: "#fff" }}> <img src={matka} alt="" srcset="" />Matka Boss</h1>
                  </div>
                </Link>
              </div>
              <div>
              </div>
              <div className="user-details">
                <div className="search-box-container d-none d-xl-block">
                  <div className="search-box">
                    <input
                      type="search"
                      placeholder="Search here"
                      className="form-control"
                      defaultValue=""
                    />
                    <a>
                      <i className="fas fa-search-plus" />
                    </a>
                  </div>
                </div>
                <div className="header-rules ms-3 ">
                  <div>
                    <a className="rules-link pointer">
                      <b>Rules</b>
                    </a>
                  </div>
                </div>
                <div className="ms-3 d-none d-xl-flex" />
                <div className="user-balance ms-1 ms-xl-3">
                  <div style={{fontSize: "19px"}}>
                    <span>Balance:</span>
                    <b>{user.Default}</b>
                  </div>
                  <div>
                    <span>Exp:</span>
                    <b className="pointer">{user.Exposure}</b>
                    <div className="dropdown">
                      <a
                        className="user-name ms-1 ms-xl-3 d-inline-block d-xl-none dropdown-toggle"
                        id="react-aria2375392432-1"
                        aria-expanded="false"
                      >
                        {user.username}

                        <div onClick={btnClickHandler}>

                          <i className="fas fa-chevron-down ms-1" />
                        </div>
                        

                      </a>
                      <div id='dropMenu' style={{display: Clicked ? "block" : "none" }}>
                          <ul
                            x-placement="bottom-start"
                            aria-labelledby="react-aria2375392432-1"
                            className="dropdown-menu show"
                            
                            data-popper-reference-hidden="false"
                            data-popper-escaped="false"
                            data-popper-placement="bottom-start"
                            style={{
                              right: "0px",
                              transform: "translate(4px, 21px)"
                            }}
                          >
                            <Link to={"/accountStatment"}>
                              <li data-rr-ui-dropdown-item="" className="dropdown-item">
                                Account Statement
                              </li>
                            </Link>
                            <Link to={"/current-bet"}>
                              <li data-rr-ui-dropdown-item="" className="dropdown-item">
                                Current Bet
                              </li>
                            </Link>
                            {/* <a href="/activity-log">
                              <li data-rr-ui-dropdown-item="" className="dropdown-item">
                                Activity Logs
                              </li>
                            </a>
                            <a href="/casino-results">
                              <li data-rr-ui-dropdown-item="" className="dropdown-item">
                                Casino Results
                              </li>
                            </a>
                            <a href="/live-casino-bets">
                              <li data-rr-ui-dropdown-item="" className="dropdown-item">
                                Live Casino Bets
                              </li>
                            </a> */}
                            <a>
                              <li className="dropdown-item">Set Button Values</li>
                            </a>
                            <Link to={"/my-bets"}>
                              <li data-rr-ui-dropdown-item="" className="dropdown-item">
                                My Bets
                              </li>
                            </Link>
                            <a href="/change-password">
                              <li data-rr-ui-dropdown-item="" className="dropdown-item">
                                Change Password
                              </li>
                            </a>
                            <a className="d-xl-none">
                              <li className="dropdown-item">Rules</li>
                            </a>
                            <a className="dropdown-item d-xl-none">
                              Balance
                              <div className="form-check float-end">
                                <input className="form-check-input" type="checkbox" defaultChecked="" />
                              </div>
                            </a>
                            <a className="dropdown-item d-xl-none">
                              Exposure
                              <div className="form-check float-end">
                                <input className="form-check-input" type="checkbox" defaultChecked="" />
                              </div>
                            </a>
                            <hr className="dropdown-divider" role="separator" />
                            <Link to={"/"}>
                            
                            <li data-rr-ui-dropdown-item="" className="dropdown-item">
                              SignOut
                            </li>
                            
                            </Link>
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="dropdown">
                  <a
                    className="user-name ms-3 d-none d-xl-block dropdown-toggle"
                    id="react-aria2375392432-2"
                    aria-expanded="false"
                  >
                    {user.username}
                    <i onClick={btnClickHandler} className="fas fa-chevron-down ms-1" />
                  </a>
                  <ul
                    x-placement="bottom-start"
                    aria-labelledby="react-aria2375392432-2"
                    className="dropdown-menu"
                    data-popper-reference-hidden="true"
                    data-popper-escaped="true"
                    data-popper-placement="bottom-start"
                    style={{
                      position: "absolute",
                      inset: "0px auto auto 0px",
                      transform: "translate(0px, 2px)"
                    }}
                  >
                    <a href="/account-statement">
                      <li data-rr-ui-dropdown-item="" className="dropdown-item">
                        Account Statement
                      </li>
                    </a>
                    <a href="/current-bet">
                      <li data-rr-ui-dropdown-item="" className="dropdown-item">
                        Current Bet
                      </li>
                    </a>
                    <a href="/activity-log">
                      <li data-rr-ui-dropdown-item="" className="dropdown-item">
                        Activity Logs
                      </li>
                    </a>
                    <a href="/casino-results">
                      <li data-rr-ui-dropdown-item="" className="dropdown-item">
                        Casino Results
                      </li>
                    </a>
                    <a href="/live-casino-bets">
                      <li data-rr-ui-dropdown-item="" className="dropdown-item">
                        Live Casino Bets
                      </li>
                    </a>
                    <a>
                      <li className="dropdown-item">Set Button Values</li>
                    </a>
                    <a href="/my-bets">
                      <li data-rr-ui-dropdown-item="" className="dropdown-item">
                        My Bets
                      </li>
                    </a>
                    <a href="/change-password">
                      <li data-rr-ui-dropdown-item="" className="dropdown-item">
                        Change Password
                      </li>
                    </a>
                    <a className="d-xl-none">
                      <li className="dropdown-item">Rules</li>
                    </a>
                    <a className="dropdown-item d-xl-none">
                      Balance
                      <div className="form-check float-end">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultChecked=""
                        />
                      </div>
                    </a>
                    <a className="dropdown-item d-xl-none">
                      Exposure
                      <div className="form-check float-end">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultChecked=""
                        />
                      </div>
                    </a>
                    <hr className="dropdown-divider" role="separator" />
                    <li data-rr-ui-dropdown-item="" className="dropdown-item">
                      SignOut
                    </li>
                  </ul>
                </div>
              </div>
              <div className="search-box-container d-xl-none">
                
                <div className="depowith">
                  <div className="d-xl-none d-flex justify-content-center" />
                </div>
              </div>
              <div className="news">
                <marquee scrollamount={3}>
                  TAKE A CHANCE, EMBRACE THE THRILL. UNLEASH THE EXCITEMENT WITH OUR
                  CAPTIVATING GAMES.
                </marquee>
              </div>
            </div>
          </section>
        </div>
      </>

    </div>
  )
}
