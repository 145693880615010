import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserContext';
import Header from './Header'
import axios from '../utils/axiosConfig'; // Import your axios configuration


export default function AccountStatmentUser() {
    const { user, updateUser } = useContext(UserContext);
    const [creatorUsers, setCreatorUsers] = useState({});
    const [combined, setCombined] = useState([]);


    const fetchUser = async () => {
        try {
            const response = await axios.post('/auth/user', { username: user.Creator });
            setCreatorUsers(response.data);
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        if (user.Creator) {
            const loadData = async () => {
                await fetchUser();
            };
            loadData();
        }
    }, [user.Creator]);

    // Log creatorUsers whenever it updates
    useEffect(() => {
        
        setCombinedHistory();
    }, [creatorUsers]);

    const setCombinedHistory = () => {
        const combinedHistory = [
            ...(creatorUsers[0]?.depositHistory || [])
                .filter(deposit => deposit.from === user.username)
                .map(deposit => ({
                    ...deposit,
                    type: 'deposit', // Add a type field for identification
                })),
            ...(creatorUsers[0]?.withdrawHistory || [])
                .filter(withdraw => withdraw.from === user.username)
                .map(withdraw => ({
                    ...withdraw,
                    type: 'withdraw', // Add a type field for identification
                })),
        ].sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date ascending

        setCombined(combinedHistory);
    }

    console.log(combined);


    return (
        <div>
            <Header style={{ width: "100vw" }} />


            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Account Statement</h4>
                </div>
                <div className="card-body">
                    <div className="report-form">
                        {/* <form className="row row5">
                            <div className="col-lg-2 col-md-3 col-6">
                                <div className="react-datepicker-wrapper">
                                    <div className="react-datepicker__input-container">
                                        <div className="mb-2 custom-datepicker">
                                            <input
                                                type="text"
                                                className="form-control"
                                                disabled=""
                                                defaultValue="08/10/2024"
                                            />
                                            <i className="far fa-calendar" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-6">
                                <div className="react-datepicker-wrapper">
                                    <div className="react-datepicker__input-container">
                                        <div className="mb-2 custom-datepicker">
                                            <input
                                                type="text"
                                                className="form-control"
                                                disabled=""
                                                defaultValue="15/10/2024"
                                            />
                                            <i className="far fa-calendar" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="mb-2 input-group position-relative">
                                    <select className="form-select" name="type">
                                        <option value="" disabled="">
                                            Select Report Type
                                        </option>
                                        <option value={1}>Deposite/Withdraw Reports</option>
                                        <option value={2}>Sport Report</option>
                                        <option value={3}>Casino Reports</option>
                                        <option value={4}>Third-Party Casino Reports</option>
                                    </select>
                                </div>
                            </div>
                            
                        </form> */}
                        {/* <div className="row row10 mt-2 justify-content-between">
                            <div className="col-lg-2 col-6">
                                <div className="mb-2 input-group position-relative">
                                    <span className="me-2">Show</span>
                                    <select className="form-select">
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                    </select>
                                    <span className="ms-2">Entries</span>
                                </div>
                            </div>
                            <div className="col-lg-2 col-6">
                                <div className="mb-2 input-group position-relative">
                                    <span className="me-2">Search:</span>
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="2 records..."
                                        defaultValue=""
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className="mt-2 table-responsive">
                            <table role="table" className="table table-bordered table-striped">
                                <thead>
                                    <tr role="row">
                                        <th colSpan={1} role="columnheader" className="report-date">
                                            Date
                                        </th>
                                        <th
                                            colSpan={1}
                                            role="columnheader"
                                            className="report-sr text-end"
                                        >
                                            Sr no
                                        </th>
                                        <th
                                            colSpan={1}
                                            role="columnheader"
                                            className="report-amount text-end"
                                        >
                                            Credit
                                        </th>
                                        <th
                                            colSpan={1}
                                            role="columnheader"
                                            className="report-amount text-end"
                                        >
                                            Debit
                                        </th>
                                        
                                        <th colSpan={1} role="columnheader">
                                            Remark
                                        </th>
                                    </tr>
                                </thead>
                                <tbody role="rowgroup">
                                    
                                    

                                    {combined.length > 0 ? (
                                        combined.map((entry, index) => (
                                            <tr key={index} role="row" tabIndex={0} aria-rowindex={index + 1} className="nocursor">
                                                <td aria-colindex={1} role="cell" className="">
                                                    {new Date(entry.date).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}
                                                </td>
                                                <td role="cell" className="report-sr text-end">
                                                    {index + 1}
                                                </td>
                                                <td aria-colindex={2} role="cell" className="">
                                                    {entry.type != 'withdraw' ? (
                                                        <div className="text-right text-success">
                                                            <span>{entry.amount}</span>
                                                        </div>
                                                    ) : (
                                                        <div className="text-right">
                                                            <span />
                                                        </div>
                                                    )}
                                                </td>
                                                <td aria-colindex={3} role="cell" className="">
                                                    {entry.type != 'deposit' ? (
                                                        <div className="text-right text-danger">
                                                            <span>{entry.amount}</span>
                                                        </div>
                                                    ) : (
                                                        <div className="text-right text-danger">
                                                            <span></span>
                                                        </div>
                                                    )}
                                                </td>
                                               
                                                
                                                <td aria-colindex={6} role="cell" className="">
                                                    {entry.from + " / " + creatorUsers[0].username}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <li>No transactions found.</li>
                                    )}



                                </tbody>
                            </table>
                        </div>
                        <div className="custom-pagination mt-2">
                            <div disabled="">First</div>
                            <div disabled="">Previous</div>
                            <div disabled="">Next</div>
                            <div disabled="">Last</div>
                            <div>
                                <span className="me-2">
                                    Page <b>1 of 1</b>
                                </span>
                                <span className="me-2">| Go to Page</span>
                                <input className="form-control" type="number" defaultValue={1} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}
