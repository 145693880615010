import React, { useContext, useState } from 'react'
import Header from './Header'
import { useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import axios from '../utils/axiosConfig'; // Import your axios configuration
import { useLocation } from 'react-router-dom';


export default function Bet() {
    const location = useLocation();
    const pathName = location.pathname;
    const pathText = pathName.split('/')[1];
    const { user, updateUser } = useContext(UserContext);
    const [selectedNumber, setSelectedNumber] = useState([]);
    const [isClicked, setisClicked] = useState(true);
    const [stake, setStake] = useState("");
    const [selectedBet, setSelectedBet] = useState(null);
    const [oddsank, setOddsank] = useState(9.5);
    const [error, seterror] = useState("");
    const finalStake = stake || selectedBet;
    const [event, setEvent] = useState('Ank')
    const [openBackground, setOpenBackground] = useState('white');
    const [closeBackground, setCloseBackground] = useState('white');
    const [timeAction, setTimeAction] = useState('');

    const handleBetSelection = (value) => {
        setSelectedBet(value);
        setStake(value);
    };
    const openBetHandler = (e) => {
        setTimeAction('open');
        setOpenBackground('#ff00a2'); // Update open button background color
        setCloseBackground('white'); // Reset close button background color
    };
    const closeBetHandler = (e) => {
        setTimeAction('close');
        setCloseBackground('#ff00a2'); // Update close button background color
        setOpenBackground('white'); // Reset open button background color
        setEvent('Ank');
        document.getElementById("placeBetDiv").style.display = "block";
        document.getElementById("akaDiv").style.display = "block";
        document.getElementById("jodi").style.display = "none";
        document.getElementById("single").style.display = "none";
        document.getElementById("doublePatti").style.display = "none";
        document.getElementById("triple").style.display = "none";
        setOddsank(9.5)

    };

    const handleSubmit = async (e) => {  
        e.preventDefault(); // Prevent default form submission
        let finalStake = stake || selectedBet; // Use custom stake or selected bet
        finalStake = Number(finalStake) * selectedNumber.length;
        const userBal = user.AvailableBalance
        seterror("");
        if (finalStake > userBal) {
            finalStake = 0;
            seterror("low Balance / amount should be greater than 10");
        }
        else if (selectedNumber.length === 0) {
            seterror("Select a number to bet on");
        }
        else if (timeAction === '') {
            seterror("select open or close");
        }
        else if(finalStake < 10){
            seterror("Amount should be greater than 10");
        }
        else if(finalStake > 100000){
            seterror("Amount should be less than 100000");
        }
        
        else {
            console.log("Final bet Amount: ", finalStake);
            console.log("Numbers: ", selectedNumber);
            console.log("Event: ", event);  
            console.log(user);
            console.log(timeAction);
            const betData = {
                "Action": 1,
                "amount": finalStake,
                "rate": oddsank,
                "number": selectedNumber,
                "event": pathText,
                "nation": event,
                "timeAction" :timeAction,
                "username": user.username
            }
            try {
                const response = await axios.post('auth/kalyan', betData);
                console.log('Bet added successfully:', response.data);
            
                // Check if the response contains 'sasas' and handle accordingly
                if (response.data !== 'Time Over' && response.data.user) {
                    updateUser(response.data.user);  // Assuming updateUser is a function to update the user context or state
                }
                seterror('Bet added successfully');

                if(response.data === 'Time Over'){
                    seterror("Bet close");
                }
            } catch (error) {
                console.error('Error while adding bet:', error.response ? error.response.data : error.message);
            }
        }

        const allDivs = document.querySelectorAll('.circleDiv'); // Assuming you are using class 'number-div' for each number div
        allDivs.forEach((div) => {
            div.style.background = ""; // Reset all divs' backgrounds
        });
        setSelectedNumber([]);
    };

    const handleReset = () => {
        setStake("");
        setSelectedBet(null);
    };


    const clickHandler = () => {
        document.getElementById("placeBetDiv").style.display = "block";
        document.getElementById("akaDiv").style.display = "block";
        document.getElementById("jodi").style.display = "none";
        document.getElementById("single").style.display = "none";
        document.getElementById("doublePatti").style.display = "none";
        document.getElementById("triple").style.display = "none";
        setOddsank(9.5)
        const allDivs = document.querySelectorAll('.circleDiv'); // Assuming you are using class 'number-div' for each number div
        allDivs.forEach((div) => {
            div.style.background = ""; // Reset all divs' backgrounds
        });
        setSelectedNumber([]);
        setEvent('Ank');
    }
    const clickHandler2 = () => {
        document.getElementById("placeBetDiv").style.display = "block";
        document.getElementById("jodi").style.display = "block";
        document.getElementById("akaDiv").style.display = "none";
        document.getElementById("doublePatti").style.display = "none";
        document.getElementById("single").style.display = "none";
        document.getElementById("triple").style.display = "none";
        setOddsank(90)
        const allDivs = document.querySelectorAll('.circleDiv'); // Assuming you are using class 'number-div' for each number div
        allDivs.forEach((div) => {
            div.style.background = ""; // Reset all divs' backgrounds
        });
        setSelectedNumber([]);
        setEvent('Jodi');
    }
    const clickHandler3 = () => {
        document.getElementById("placeBetDiv").style.display = "block";
        document.getElementById("single").style.display = "block";
        document.getElementById("akaDiv").style.display = "none";
        document.getElementById("jodi").style.display = "none";
        document.getElementById("doublePatti").style.display = "none";
        document.getElementById("triple").style.display = "none";
        setOddsank(150)
        const allDivs = document.querySelectorAll('.circleDiv'); // Assuming you are using class 'number-div' for each number div
        allDivs.forEach((div) => {
            div.style.background = ""; // Reset all divs' backgrounds
        });
        setSelectedNumber([]);
        setEvent('SinglePatti');
    }
    const clickHandler4 = () => {
        document.getElementById("placeBetDiv").style.display = "block";
        document.getElementById("single").style.display = "none";
        document.getElementById("akaDiv").style.display = "none";
        document.getElementById("jodi").style.display = "none";
        document.getElementById("triple").style.display = "none";
        document.getElementById("doublePatti").style.display = "block";
        setOddsank(300)
        const allDivs = document.querySelectorAll('.circleDiv'); // Assuming you are using class 'number-div' for each number div
        allDivs.forEach((div) => {
            div.style.background = ""; // Reset all divs' backgrounds
    
        });
        setSelectedNumber([]);
        setEvent('DoublePatti');
    }
    const clickHandler5 = () => {
        document.getElementById("placeBetDiv").style.display = "block";
        document.getElementById("single").style.display = "none";
        document.getElementById("akaDiv").style.display = "none";
        document.getElementById("jodi").style.display = "none";
        document.getElementById("doublePatti").style.display = "none";
        document.getElementById("triple").style.display = "block";
        setOddsank(900)
        const allDivs = document.querySelectorAll('.circleDiv'); // Assuming you are using class 'number-div' for each number div
        allDivs.forEach((div) => {
            div.style.background = ""; // Reset all divs' backgrounds
        });
        setSelectedNumber([]);
        setEvent('TriplePatti');
    }


    const clickHandlerNumber = (e) => {
        const number = e.target.dataset.number;

        // Reset the background color of all divs
        const allDivs = document.querySelectorAll('.circleDiv'); // Assuming you are using class 'number-div' for each number div
        allDivs.forEach((div) => {
            div.style.background = ""; // Reset all divs' backgrounds
        });
    
        // Set background of the clicked element to red
        e.target.style.background = "red";
    
        // Update the selectedNumber to only contain the clicked number
        setSelectedNumber([number]);

    }



    useEffect(() => {
        //   console.log(selectedNumber);
    }, [])

    return (
        <div>
            <Header style={{ width: "100vw" }} />

            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Choose Your Bet</title>
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        "\n        body {\n            background-color: white;\n            font-family: 'Arial', sans-serif;\n            color: white;\n        }\n        .card {\n            background-color: #ff3366;\n            width: 150px;\n            height: 150px;\n            border-radius: 12px;\n            display: flex;\n            flex-direction: column;\n            justify-content: center;\n            align-items: center;\n            transition: transform 0.3s;\n        }\n        .card:hover {\n            transform: scale(1.1);\n        }\n        .icon {\n            font-size: 2.5rem;\n            margin-bottom: 10px;\n            color: #ffcc00;\n        }\n        .label {\n            font-size: 1.2rem;\n            font-weight: bold;\n        }\n    "
                }}
            />
            <div className="flex items-center flex-col">
                <div className='flex gap-2 m-3'>
                    {/* <button type="button" className="btn btn-success me-1">
                        open
                    </button>
                    <button type="submit" className="btn btn-danger">
                        close
                    </button> */}


                    <div
                        id="open"
                        onClick={openBetHandler}
                        className="openCloseDiv"
                        style={{ background: openBackground }}
                    >
                        <h1 style={{ color: 'black' }}>Open</h1>
                    </div>
                    <div
                        id="closeeee"
                        onClick={closeBetHandler}
                        className="openCloseDiv"
                        style={{ background: closeBackground }}
                    >
                        <h1 style={{ color: 'black' }}>Close</h1>
                    </div>

                </div>
                <div className="flex grid grid-cols-3 sm:grid-cols-5 gap-2">
                    <div className="card akadiv" data-divv="akaDiv" onClick={clickHandler} style={{ width: 100, height: 100 }}>
                        <div className="icon">♠</div>
                        <div className="label text-center">Ank</div>
                    </div>
                    <div className="card" data-divv="jodi" onClick={clickHandler2} style={{ width: 100, height: 100, display: timeAction === 'close' ? 'none' : 'flex' }}>
                        <div className="icon">♥</div>
                        <div className="label text-center">Jodi</div>
                    </div>
                    <div className="card" onClick={clickHandler3} style={{ width: 100, height: 100 }}>
                        <div className="">🃏</div>
                        <div className="label text-center">Single Patti</div>
                    </div>
                    <div className="card" onClick={clickHandler4} style={{ width: 100, height: 100 }}>
                        <div className="">🃏🃏</div>
                        <div className="label text-center">Double Patti</div>
                    </div>
                    <div className="card" onClick={clickHandler5} style={{ width: 100, height: 100 }}>
                        <div className="">🃏🃏🃏</div>
                        <div className="label text-center">Triple Patti</div>
                    </div>
                </div>
                <p className="text-orange-500 text-xl font-semibold text-center px-4">
                    Choose your favorite bet.
                </p>
            </div>
            <div id="akaDiv" style={{ display: "none" }}>
                <div id="numberdiv" style={{ display: "flex" }}>
                    {[...Array(10).keys()].map((num) => (
                        <div className='circleDiv' key={num} data-number={num} onClick={clickHandlerNumber}>
                            {num}
                        </div>
                    ))}
                </div>

            </div>
            <div id="jodi" style={{ display: "none" }}>
                <div id="numberdiv" style={{ display: "flex" }}>
                    {[...Array(100).keys()].map((num) => {
                        const formattedNumber = (num).toString().padStart(2, '0');
                        return (
                            <div className='circleDiv' key={formattedNumber} data-number={formattedNumber} onClick={clickHandlerNumber}>
                                {formattedNumber}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div id="triple" style={{ display: "none" }}>
                <div id="numberdiv" style={{ display: "flex" }}>

                    <div className='circleDiv' data-number="777" onClick={clickHandlerNumber}>777</div>
                    <div className='circleDiv' data-number="444" onClick={clickHandlerNumber}>444</div>
                    <div className='circleDiv' data-number="111" onClick={clickHandlerNumber}>111</div>
                    <div className='circleDiv' data-number="888" onClick={clickHandlerNumber}>888</div>
                    <div className='circleDiv' data-number="555" onClick={clickHandlerNumber}>555</div>
                    <div className='circleDiv' data-number="222" onClick={clickHandlerNumber}>222</div>
                    <div className='circleDiv' data-number="999" onClick={clickHandlerNumber}>999</div>
                    <div className='circleDiv' data-number="666" onClick={clickHandlerNumber}>666</div>
                    <div className='circleDiv' data-number="333" onClick={clickHandlerNumber}>333</div>
                    <div className='circleDiv' data-number="000" onClick={clickHandlerNumber}>000</div>

                </div>
            </div>
            <div id="doublePatti" style={{ display: "none" }}>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>1</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="100" onClick={clickHandlerNumber}>100</div>
                    <div className='circleDiv' data-number="119" onClick={clickHandlerNumber}>119</div>
                    <div className='circleDiv' data-number="155" onClick={clickHandlerNumber}>155</div>
                    <div className='circleDiv' data-number="227" onClick={clickHandlerNumber}>227</div>
                    <div className='circleDiv' data-number="335" onClick={clickHandlerNumber}>335</div>
                    <div className='circleDiv' data-number="344" onClick={clickHandlerNumber}>344</div>
                    <div className='circleDiv' data-number="399" onClick={clickHandlerNumber}>399</div>
                    <div className='circleDiv' data-number="588" onClick={clickHandlerNumber}>588</div>
                    <div className='circleDiv' data-number="669" onClick={clickHandlerNumber}>669</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>2</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div data-number="110" onClick={clickHandlerNumber}>110</div>
                    <div data-number="200" onClick={clickHandlerNumber}>200</div>
                    <div data-number="228" onClick={clickHandlerNumber}>228</div>
                    <div data-number="255" onClick={clickHandlerNumber}>255</div>
                    <div data-number="366" onClick={clickHandlerNumber}>366</div>
                    <div data-number="499" onClick={clickHandlerNumber}>499</div>
                    <div data-number="660" onClick={clickHandlerNumber}>660</div>
                    <div data-number="688" onClick={clickHandlerNumber}>688</div>
                    <div data-number="778" onClick={clickHandlerNumber}>778</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>3</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div data-number="166" onClick={clickHandlerNumber}>166</div>
                    <div data-number="229" onClick={clickHandlerNumber}>229</div>
                    <div data-number="300" onClick={clickHandlerNumber}>300</div>
                    <div data-number="337" onClick={clickHandlerNumber}>337</div>
                    <div data-number="355" onClick={clickHandlerNumber}>355</div>
                    <div data-number="445" onClick={clickHandlerNumber}>445</div>
                    <div data-number="599" onClick={clickHandlerNumber}>599</div>
                    <div data-number="779" onClick={clickHandlerNumber}>779</div>
                    <div data-number="788" onClick={clickHandlerNumber}>788</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>4</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div data-number="112" onClick={clickHandlerNumber}>112</div>
                    <div className='circleDiv' data-number="220" onClick={clickHandlerNumber}>220</div>
                    <div className='circleDiv' data-number="266" onClick={clickHandlerNumber}>266</div>
                    <div className='circleDiv' data-number="338" onClick={clickHandlerNumber}>338</div>
                    <div className='circleDiv' data-number="400" onClick={clickHandlerNumber}>400</div>
                    <div className='circleDiv' data-number="446" onClick={clickHandlerNumber}>446</div>
                    <div className='circleDiv' data-number="455" onClick={clickHandlerNumber}>455</div>
                    <div className='circleDiv' data-number="699" onClick={clickHandlerNumber}>699</div>
                    <div className='circleDiv' data-number="770" onClick={clickHandlerNumber}>770</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>5</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="113" onClick={clickHandlerNumber}>113</div>
                    <div className='circleDiv' data-number="122" onClick={clickHandlerNumber}>122</div>
                    <div className='circleDiv' data-number="177" onClick={clickHandlerNumber}>177</div>
                    <div className='circleDiv' data-number="339" onClick={clickHandlerNumber}>339</div>
                    <div className='circleDiv' data-number="366" onClick={clickHandlerNumber}>366</div>
                    <div className='circleDiv' data-number="447" onClick={clickHandlerNumber}>447</div>
                    <div className='circleDiv' data-number="500" onClick={clickHandlerNumber}>500</div>
                    <div className='circleDiv' data-number="799" onClick={clickHandlerNumber}>799</div>
                    <div className='circleDiv' data-number="889" onClick={clickHandlerNumber}>889</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>6</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="600" onClick={clickHandlerNumber}>600</div>
                    <div className='circleDiv' data-number="114" onClick={clickHandlerNumber}>114</div>
                    <div className='circleDiv' data-number="277" onClick={clickHandlerNumber}>277</div>
                    <div className='circleDiv' data-number="330" onClick={clickHandlerNumber}>330</div>
                    <div className='circleDiv' data-number="448" onClick={clickHandlerNumber}>448</div>
                    <div className='circleDiv' data-number="466" onClick={clickHandlerNumber}>466</div>
                    <div className='circleDiv' data-number="556" onClick={clickHandlerNumber}>556</div>
                    <div className='circleDiv' data-number="880" onClick={clickHandlerNumber}>880</div>
                    <div className='circleDiv' data-number="899" onClick={clickHandlerNumber}>899</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>7</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="115" onClick={clickHandlerNumber}>115</div>
                    <div className='circleDiv' data-number="133" onClick={clickHandlerNumber}>133</div>
                    <div className='circleDiv' data-number="188" onClick={clickHandlerNumber}>188</div>
                    <div className='circleDiv' data-number="223" onClick={clickHandlerNumber}>223</div>
                    <div className='circleDiv' data-number="377" onClick={clickHandlerNumber}>377</div>
                    <div className='circleDiv' data-number="449" onClick={clickHandlerNumber}>449</div>
                    <div className='circleDiv' data-number="557" onClick={clickHandlerNumber}>557</div>
                    <div className='circleDiv' data-number="566" onClick={clickHandlerNumber}>566</div>
                    <div className='circleDiv' data-number="700" onClick={clickHandlerNumber}>700</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>8</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="116" onClick={clickHandlerNumber}>116</div>
                    <div className='circleDiv' data-number="224" onClick={clickHandlerNumber}>224</div>
                    <div className='circleDiv' data-number="233" onClick={clickHandlerNumber}>233</div>
                    <div className='circleDiv' data-number="288" onClick={clickHandlerNumber}>288</div>
                    <div className='circleDiv' data-number="440" onClick={clickHandlerNumber}>440</div>
                    <div className='circleDiv' data-number="477" onClick={clickHandlerNumber}>477</div>
                    <div className='circleDiv' data-number="558" onClick={clickHandlerNumber}>558</div>
                    <div className='circleDiv' data-number="800" onClick={clickHandlerNumber}>800</div>
                    <div className='circleDiv' data-number="990" onClick={clickHandlerNumber}>990</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>9</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="117" onClick={clickHandlerNumber}>117</div>
                    <div className='circleDiv' data-number="144" onClick={clickHandlerNumber}>144</div>
                    <div className='circleDiv' data-number="199" onClick={clickHandlerNumber}>199</div>
                    <div className='circleDiv' data-number="225" onClick={clickHandlerNumber}>225</div>
                    <div className='circleDiv' data-number="388" onClick={clickHandlerNumber}>388</div>
                    <div className='circleDiv' data-number="559" onClick={clickHandlerNumber}>559</div>
                    <div className='circleDiv' data-number="577" onClick={clickHandlerNumber}>577</div>
                    <div className='circleDiv' data-number="667" onClick={clickHandlerNumber}>667</div>
                    <div className='circleDiv' data-number="900" onClick={clickHandlerNumber}>900</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>0</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="118" onClick={clickHandlerNumber}>118</div>
                    <div className='circleDiv' data-number="226" onClick={clickHandlerNumber}>226</div>
                    <div className='circleDiv' data-number="244" onClick={clickHandlerNumber}>244</div>
                    <div className='circleDiv' data-number="299" onClick={clickHandlerNumber}>299</div>
                    <div className='circleDiv' data-number="334" onClick={clickHandlerNumber}>334</div>
                    <div className='circleDiv' data-number="488" onClick={clickHandlerNumber}>488</div>
                    <div className='circleDiv' data-number="550" onClick={clickHandlerNumber}>550</div>
                    <div className='circleDiv' data-number="668" onClick={clickHandlerNumber}>668</div>
                    <div className='circleDiv' data-number="677" onClick={clickHandlerNumber}>677</div>
                </div>
            </div>
            <div id="single" style={{ display: "none" }}>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>1</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="128" onClick={clickHandlerNumber}>128</div>
                    <div className='circleDiv' data-number="137" onClick={clickHandlerNumber}>137</div>
                    <div className='circleDiv' data-number="236" onClick={clickHandlerNumber}>236</div>
                    <div className='circleDiv' data-number="678" onClick={clickHandlerNumber}>678</div>
                    <div className='circleDiv' data-number="245" onClick={clickHandlerNumber}>245</div>
                    <div className='circleDiv' data-number="290" onClick={clickHandlerNumber}>290</div>
                    <div className='circleDiv' data-number="470" onClick={clickHandlerNumber}>470</div>
                    <div className='circleDiv' data-number="579" onClick={clickHandlerNumber}>579</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>2</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="129" onClick={clickHandlerNumber}>129</div>
                    <div className='circleDiv' data-number="147" onClick={clickHandlerNumber}>147</div>
                    <div className='circleDiv' data-number="246" onClick={clickHandlerNumber}>246</div>
                    <div className='circleDiv' data-number="679" onClick={clickHandlerNumber}>679</div>
                    <div className='circleDiv' data-number="345" onClick={clickHandlerNumber}>345</div>
                    <div className='circleDiv' data-number="390" onClick={clickHandlerNumber}>390</div>
                    <div className='circleDiv' data-number="480" onClick={clickHandlerNumber}>480</div>
                    <div className='circleDiv' data-number="589" onClick={clickHandlerNumber}>589</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>3</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="120" onClick={clickHandlerNumber}>120</div>
                    <div className='circleDiv' data-number="157" onClick={clickHandlerNumber}>157</div>
                    <div className='circleDiv' data-number="256" onClick={clickHandlerNumber}>256</div>
                    <div className='circleDiv' data-number="670" onClick={clickHandlerNumber}>670</div>
                    <div className='circleDiv' data-number="139" onClick={clickHandlerNumber}>139</div>
                    <div className='circleDiv' data-number="148" onClick={clickHandlerNumber}>148</div>
                    <div className='circleDiv' data-number="346" onClick={clickHandlerNumber}>346</div>
                    <div className='circleDiv' data-number="689" onClick={clickHandlerNumber}>689</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>4</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="130" onClick={clickHandlerNumber}>130</div>
                    <div className='circleDiv' data-number="158" onClick={clickHandlerNumber}>158</div>
                    <div className='circleDiv' data-number="356" onClick={clickHandlerNumber}>356</div>
                    <div className='circleDiv' data-number="680" onClick={clickHandlerNumber}>680</div>
                    <div className='circleDiv' data-number="239" onClick={clickHandlerNumber}>239</div>
                    <div className='circleDiv' data-number="248" onClick={clickHandlerNumber}>248</div>
                    <div className='circleDiv' data-number="347" onClick={clickHandlerNumber}>347</div>
                    <div className='circleDiv' data-number="789" onClick={clickHandlerNumber}>789</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>5</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="140" onClick={clickHandlerNumber}>140</div>
                    <div className='circleDiv' data-number="159" onClick={clickHandlerNumber}>159</div>
                    <div className='circleDiv' data-number="456" onClick={clickHandlerNumber}>456</div>
                    <div className='circleDiv' data-number="690" onClick={clickHandlerNumber}>690</div>
                    <div className='circleDiv' data-number="230" onClick={clickHandlerNumber}>230</div>
                    <div className='circleDiv' data-number="258" onClick={clickHandlerNumber}>258</div>
                    <div className='circleDiv' data-number="357" onClick={clickHandlerNumber}>357</div>
                    <div className='circleDiv' data-number="780" onClick={clickHandlerNumber}>780</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>6</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="123" onClick={clickHandlerNumber}>123</div>
                    <div className='circleDiv' data-number="178" onClick={clickHandlerNumber}>178</div>
                    <div className='circleDiv' data-number="268" onClick={clickHandlerNumber}>268</div>
                    <div className='circleDiv' data-number="367" onClick={clickHandlerNumber}>367</div>
                    <div className='circleDiv' data-number="240" onClick={clickHandlerNumber}>240</div>
                    <div className='circleDiv' data-number="259" onClick={clickHandlerNumber}>259</div>
                    <div className='circleDiv' data-number="457" onClick={clickHandlerNumber}>457</div>
                    <div className='circleDiv' data-number="790" onClick={clickHandlerNumber}>790</div>

                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>7</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv'  data-number="124" onClick={clickHandlerNumber}>124</div>
                    <div className='circleDiv'  data-number="179" onClick={clickHandlerNumber}>179</div>
                    <div className='circleDiv'  data-number="269" onClick={clickHandlerNumber}>269</div>
                    <div className='circleDiv'  data-number="467" onClick={clickHandlerNumber}>467</div>
                    <div className='circleDiv'  data-number="340" onClick={clickHandlerNumber}>340</div>
                    <div className='circleDiv'  data-number="359" onClick={clickHandlerNumber}>359</div>
                    <div className='circleDiv'  data-number="458" onClick={clickHandlerNumber}>458</div>
                    <div className='circleDiv'  data-number="890" onClick={clickHandlerNumber}>890</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>8</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="125" onClick={clickHandlerNumber}>125</div>
                    <div className='circleDiv' data-number="170" onClick={clickHandlerNumber}>170</div>
                    <div className='circleDiv' data-number="260" onClick={clickHandlerNumber}>260</div>
                    <div className='circleDiv' data-number="567" onClick={clickHandlerNumber}>567</div>
                    <div className='circleDiv' data-number="134" onClick={clickHandlerNumber}>134</div>
                    <div className='circleDiv' data-number="189" onClick={clickHandlerNumber}>189</div>
                    <div className='circleDiv' data-number="369" onClick={clickHandlerNumber}>369</div>
                    <div className='circleDiv' data-number="468" onClick={clickHandlerNumber}>468</div>
                    <div className='circleDiv' data-number="350" onClick={clickHandlerNumber}>350</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>9</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="135" onClick={clickHandlerNumber}>135</div>
                    <div className='circleDiv' data-number="180" onClick={clickHandlerNumber}>180</div>
                    <div className='circleDiv' data-number="360" onClick={clickHandlerNumber}>360</div>
                    <div className='circleDiv' data-number="568" onClick={clickHandlerNumber}>568</div>
                    <div className='circleDiv' data-number="234" onClick={clickHandlerNumber}>234</div>
                    <div className='circleDiv' data-number="289" onClick={clickHandlerNumber}>289</div>
                    <div className='circleDiv' data-number="379" onClick={clickHandlerNumber}>379</div>
                    <div className='circleDiv' data-number="478" onClick={clickHandlerNumber}>478</div>
                </div>
                <h3 style={{ display: "inline", color: "black", marginLeft: "47vw" }}>0</h3>
                <div id="numberdiv" style={{ display: "flex" }}>
                    <div className='circleDiv' data-number="136" onClick={clickHandlerNumber}>136</div>
                    <div className='circleDiv' data-number="145" onClick={clickHandlerNumber}>145</div>
                    <div className='circleDiv' data-number="190" onClick={clickHandlerNumber}>190</div>
                    <div className='circleDiv' data-number="460" onClick={clickHandlerNumber}>460</div>
                    <div className='circleDiv' data-number="569" onClick={clickHandlerNumber}>569</div>
                    <div className='circleDiv' data-number="235" onClick={clickHandlerNumber}>235</div>
                    <div className='circleDiv' data-number="280" onClick={clickHandlerNumber}>280</div>
                    <div className='circleDiv' data-number="370" onClick={clickHandlerNumber}>370</div>
                    <div className='circleDiv' data-number="578" onClick={clickHandlerNumber}>578</div>
                </div>
            </div>



            <form onSubmit={handleSubmit}>
                <div id="placeBetDiv" className="sidebar-box place-bet-container">
                    <div style={{ background: "#ff12eb" }} className="sidebar-title">
                        <h4>Place Bet</h4>
                    </div>

                    <div className="place-bet-box" style={{ backgroundColor: "#ffb7fe" }}>
                        <div className="place-bet-box-body">
                            <div className="place-bet-for">
                                <span>
                                    Bet for: {selectedBet ? Number(finalStake) * selectedNumber.length : "None"}
                                </span>
                            </div>

                            <div className="place-bet-odds">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={oddsank}
                                    disabled
                                />
                                
                            </div>

                            <div className="place-bet-stake">
                                <input
                                    type="number"
                                    className="form-control"
                                    value={stake}
                                    onChange={(e) => setStake(e.target.value)} // Store the custom stake
                                    placeholder="Enter custom stake"
                                />
                            </div>

                            <div className="place-bet-profit">
                                {(selectedBet || stake) ? (parseFloat(stake || selectedBet) * parseFloat(oddsank)).toFixed(2) : 0}
                            </div>
                        </div>

                        <div className="place-bet-buttons">
                            {[1000, 2000, 5000, 10000, 20000, 25000, 50000, 75000].map((bet) => (
                                <button
                                    type="button"
                                    key={bet}
                                    style={{ backgroundColor: selectedBet === bet ? "#d3d3d3" : "white" }}
                                    className="btn btn-place-bet"
                                    onClick={() => handleBetSelection(bet)}
                                >
                                    {bet}
                                </button>
                            ))}
                        </div>

                        <div className="place-bet-action-buttons">
                            <div>
                                <button type="button" className="btn btn-info">
                                    Edit
                                </button>
                            </div>
                            <div>
                                <button type="button" className="btn btn-danger me-1" onClick={handleReset}>
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-success" disabled={!stake && !selectedBet}>
                                    Submit
                                </button>
                            </div>
                        </div>
                        <p style={{ color: "red", fontSize: 25, paddingLeft: 20 }}>{error}</p>
                    </div>

                </div>

            </form>

        </div>
    )
}
