import React, { useState } from 'react'

function MyNumber() {

    const [num, setnum] = useState("")


    function getNumber() {
        fetch('https://cors-anywhere.herokuapp.com/https://dpbossss.services/',{
            
        })
            .then(response => response.text())
            .then(html => {
                // Parse the HTML string into a document object
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');

                // Now you can manipulate the doc as if it's the document of the fetched page
                console.log(doc); // Logs the entire document object

                // Example: Extracting a specific element by its class name
                const div = doc.querySelector('.some-class');
                console.log(div.innerHTML); // Logs the content of the div
            })
            .catch(error => {
                console.error('Error fetching the page:', error);
            });
    }
    return (
        <div>
            <h1>{num}</h1>
            <button onClick={getNumber}>Get Number</button>
        </div>
    )
}

export default MyNumber
