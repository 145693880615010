import React, { useState, useContext, useEffect } from 'react';
import matka from '../Images/matka.png';
import { useNavigate } from 'react-router-dom';
import useLogin from '../hooks/useLogin';
import { UserContext } from '../context/UserContext';

export default function Login() {
  const { login, loading, error } = useLogin();
  const [formData, setFormData] = useState({ "username": '', "password": '' });
  const { setUser, user , setAlertt } = useContext(UserContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(formData)
    if (!error) {
      if (user.accountType === "admin") {
        navigate('/admin');
      } else {
        navigate('/home');
      }
    } else {
      document.getElementById("wrongPara").style.display = "initial";
    }
  };

  useEffect(() => {
    
  
    setUser(null);
  }, [])
  

  return (
    <>
      {/* <div id='successMsg' class="flex w-96 shadow-lg rounded-lg alertt">
          <div class="bg-green-600 py-4 px-6 rounded-l-lg flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-white fill-current" viewBox="0 0 16 16" width="20" height="20"><path fill-rule="evenodd" d="M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.75.75 0 011.06-1.06L6 10.94l6.72-6.72a.75.75 0 011.06 0z"></path></svg>
          </div>
          <div class="px-4 py-6 bg-white rounded-r-lg flex justify-between items-center w-full border border-l-transparent border-gray-200">
            <div>Success </div>
          </div>
        </div> */}
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css" />
      <link rel="stylesheet" href="https://versionobj.ecoassetsservice.com/v15/static/admin/css/app.css" />
      <link rel="stylesheet" href="https://versionobj.ecoassetsservice.com/v15/static/front/plugin/owl/assets/owl.carousel.min.css" />
      <link rel="stylesheet" href="https://versionobj.ecoassetsservice.com/v15/static/front/plugin/owl/assets/owl.theme.default.min.css" />
      <link rel="stylesheet" href="https://versionobj.ecoassetsservice.com/v15/static/admin/css/flipclock.css" />
      <link rel="stylesheet" href="https://versionobj.ecoassetsservice.com/v15/static/admin/css/style.css" />
      <link rel="stylesheet" href="https://sitethemedata.com/sitethemes/grandexch.com/front/theme.css" />
      <link rel="stylesheet" href="https://versionobj.ecoassetsservice.com/v15/static/admin/css/custom.css" />
      <link rel="stylesheet" href="https://versionobj.ecoassetsservice.com/v15/static/admin/css/responsive.css" />

      <div style={{ background: "#ff00a2" }}>
        <section className="login-mn" style={{ background: "#ff00a2", height: "100vh" }}>
          <div className="log-logo m-b-20">
            <a href="/">
              <div className="f-pti">
                <h1 style={{ color: "#fff", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img src={matka} alt="" />Matka Boss
                </h1>
              </div>
            </a>
          </div>
          <div className="log-fld">
            <h2 className="text-center">Sign In</h2>
            <form onSubmit={handleSubmit}>
              <div id="input-group-1" role="group" className="form-group">
                <div>
                  <input
                    className="form-control"
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                    placeholder='Username'
                  />
                </div>
              </div>
              <div id="input-group-2" role="group" className="form-group">
                <div>
                  <input
                    className="form-control form-control"
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    placeholder='Password'
                  />
                </div>
              </div>
              <div id='wrongPara' style={{ display: "none" }}>
                <p style={{ color: "red" }}>Wrong username or password, try again!</p>
              </div>
              <div className="form-group text-center">
                <button type='submit' disabled={loading} style={{ backgroundColor: "#ff00a2" }}
                  className="btn btn btn-submit btn-login btn-secondary">
                  Login <i className="fas fa-sign-in-alt" />
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
}
