import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserContext';
// import AccountList from './AccountList';
import { Link } from 'react-router-dom';
import Admin from './Admin';
import AccBl from './AccBl';
import axios from '../utils/axiosConfig'; // Import your axios configuration




export default function AccountList() {
  const { user, updateUser } = useContext(UserContext);
  const [creatorUsers, setCreatorUsers] = useState([]);
  const [curentuser, setCurentuser] = useState({});
  const [error, setError] = useState('');


  const closeHandler = () => {
    document.getElementById('__BVID__103___BV_modal_content_').style.display = "none"
    document.getElementById('__BVID__104___BV_modal_content_').style.display = "none"
    document.getElementById('__BVID__102___BV_modal_content_').style.display = "none"
    document.getElementById('__BVID__105___BV_modal_content_').style.display = "none"

  }

  const withdrawHandler = async (e) => {

    const amount = parseFloat(e.target.elements.userDipositeamount.value);
    const remark = e.target.elements.userDipositeremark.value;
    const transactionPassword = e.target.elements.userDipositempassword.value;

    console.log(amount);
    console.log(remark);
    console.log(transactionPassword);


    e.preventDefault(); // Prevent default form submission

    // Validation checks
    if (transactionPassword !== user.username) {
      setError('Transaction Password wrong.');
      return;
    }

    if (amount <= 20) {
      setError('Amount must be greater than 20.');
      return;
    }

    if (!remark.trim()) {
      setError('Remark is required.');
      return;
    }


    const submitData = {
      "withdrawamount": amount,
      "username": curentuser.username,
      "creator": curentuser.Creator,
      "action": 2,
      "remark":remark
    }
    try {
      const response = await axios.post("/auth/updateUser", submitData);

      // Handle success response
      if (response.status === 200) {
        if (response.data.message === 'Withdraw successful.') {
          document.getElementById('__BVID__104___BV_modal_content_').style.display = "none"
          fetchUser();

          updateUser(response.data.creator);
          console.log(response.data.creator);
          

        }
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Error updating user:', error.message);
      setError("Insufficient balance.");
    }
    // If validation passes




  }
  const depositHandler = async (e) => {

    const amount = parseFloat(e.target.elements.userDipositeamount.value);
    const remark = e.target.elements.userDipositeremark.value;
    const transactionPassword = e.target.elements.userDipositempassword.value;

    // console.log(amount);
    console.log(remark);
    // console.log(transactionPassword);


    e.preventDefault(); // Prevent default form submission

    // Validation checks
    if (transactionPassword !== user.username) {
      setError('Transaction Password must be 5545.');
      return;
    }

    if (amount <= 20) {
      setError('Amount must be greater than 20.');
      return;
    }

    if (!remark.trim()) {
      setError('Remark is required.');
      return;
    }


    const submitData = {
      "depositAmount": amount,
      "username": curentuser.username,
      "creator": curentuser.Creator,
      "remark":remark
    }
    try {
      const response = await axios.post("/auth/updateUser", submitData);

      // Handle success response
      if (response.status === 200) {
        if (response.data.message === "Deposit successful.") {
          document.getElementById('__BVID__103___BV_modal_content_').style.display = "none"
          fetchUser();

          updateUser(response.data.creator);
          console.log(response.data.creator);
          

        }
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Error updating user:', error);
      setError("Insufficient balance.");
    }
    // If validation passes

    


  }

  const WButtonHandler = (e) => {


    const userId = e.target.dataset.userid;

    setCurentuser(creatorUsers.filter((user) => user.username === userId)[0])



    document.getElementById('__BVID__104___BV_modal_content_').style.display = "block"
  }

  const cButtonHandler = (e) => {


    const userId = e.target.dataset.userid;

    setCurentuser(creatorUsers.filter((user) => user.username === userId)[0])



    document.getElementById('__BVID__102___BV_modal_content_').style.display = "block"
  }

  const cbuttonSubmit = async (e) => {
    e.preventDefault();

    const creditReference = parseFloat(e.target.elements.userCreditUpdateamount.value);
    const transactionPassword = e.target.elements.userCreditUpdatempassword.value;

    console.log(creditReference);
    console.log(transactionPassword);

    if (transactionPassword !== user.username) {
      setError('Transaction Password must be 5545.');
      return;
    }

    const submitData = {
      "creditReference": creditReference,
      "username": curentuser.username,
      "action": 3
    }
    try {
      const response = await axios.post("/auth/updateUser", submitData);

      // Handle success response
      if (response.status === 200) {
        if (response.data.message === 'cr Change successful.') {
          document.getElementById('__BVID__102___BV_modal_content_').style.display = "none"
          fetchUser();
          setError("");
        }
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Error updating user:', error.message);
      setError("Insufficient balance.");
    }

  }

  const LButtonHandler = async (e) => {
    const userId = e.target.dataset.userid;

    setCurentuser(creatorUsers.filter((user) => user.username === userId)[0])



    document.getElementById('__BVID__105___BV_modal_content_').style.display = "block"

  }



  const depositButtonHandler = (e) => {


    const userId = e.target.dataset.userid;

    setCurentuser(creatorUsers.filter((user) => user.username === userId)[0])

    // console.log("in depositHandler");

    // console.log(curentuser)



    document.getElementById('__BVID__103___BV_modal_content_').style.display = "block"
  }





  const fetchUser = async () => {
    try {
      const response = await axios.post('/auth/users', { "username": user.username });

      setCreatorUsers(response.data);
      console.log(response.data);

    } catch (error) {
      console.log(error)
    }



  }


  useEffect(() => {
    fetchUser();


  }, [])



  return (
    <div>
      <Admin />
      

      <div
        id="__BVID__105___BV_modal_content_"
        tabIndex={-1}
        className="modal-content"
      >
        <header id="__BVID__102___BV_modal_header_" className="modal-header">
          <h5 className="modal-title">Exposure Limit</h5>{" "}
          <button onClick={closeHandler} type="button" data-dismiss="modal" className="close text-white">
            ×
          </button>
        </header>
        <div id="__BVID__102___BV_modal_body_" className="modal-body credit-mdl">
          {" "}
          {/**/}{" "}
          <form onSubmit={cbuttonSubmit} data-vv-scope="userCreditUpdate" method="post">
            <div className="form-group row">
              <label className="col-form-label col-4">Old Limit</label>{" "}
              <div className="col-8">
                <input
                  placeholder="Old Credit"
                  type="text"
                  readOnly="readonly"
                  name="userCreditUpdateusrnameamount"
                  className="form-control txt-right"
                  value={curentuser.ExposureLimit}
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-form-label col-4">New Limit</label>{" "}
              <div className="col-8 form-group-feedback form-group-feedback-right">
                <input
                  type="text"
                  name="userCreditUpdateamount"
                  className="form-control txt-right"
                  aria-required="true"
                  aria-invalid="false"
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-form-label col-4">Transaction Password</label>{" "}
              <div className="col-8 form-group-feedback form-group-feedback-right">
                <input
                  name="userCreditUpdatempassword"
                  type="password"
                  className="form-control"
                  aria-required="true"
                  aria-invalid="false"
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <div className="col-12 text-right">
                <button type="button" className="btn btn-back">
                  <i className="fas fa-undo mr-1" />
                  Back
                </button>{" "}
                <button type="submit" className="btn btn-primary">
                  submit
                  <i className="fas fa-sign-in-alt ml-1" />
                </button>
              </div>
            </div>
          </form>
          <p style={{ color: "red" }}>{error}</p>
        </div>
        {/**/}
      </div>


      
      <div
        id="__BVID__102___BV_modal_content_"
        tabIndex={-1}
        className="modal-content"
      >
        <header id="__BVID__102___BV_modal_header_" className="modal-header">
          <h5 className="modal-title">Credit</h5>{" "}
          <button onClick={closeHandler} type="button" data-dismiss="modal" className="close text-white">
            ×
          </button>
        </header>
        <div id="__BVID__102___BV_modal_body_" className="modal-body credit-mdl">
          {" "}
          {/**/}{" "}
          <form onSubmit={cbuttonSubmit} data-vv-scope="userCreditUpdate" method="post">
            <div className="form-group row">
              <label className="col-form-label col-4">Old Credit</label>{" "}
              <div className="col-8">
                <input
                  placeholder="Old Credit"
                  type="text"
                  readOnly="readonly"
                  name="userCreditUpdateusrnameamount"
                  className="form-control txt-right"
                  value={curentuser.creditReference}
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-form-label col-4">New Credit</label>{" "}
              <div className="col-8 form-group-feedback form-group-feedback-right">
                <input
                  type="text"
                  name="userCreditUpdateamount"
                  className="form-control txt-right"
                  aria-required="true"
                  aria-invalid="false"
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-form-label col-4">Transaction Password</label>{" "}
              <div className="col-8 form-group-feedback form-group-feedback-right">
                <input
                  name="userCreditUpdatempassword"
                  type="password"
                  className="form-control"
                  aria-required="true"
                  aria-invalid="false"
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <div className="col-12 text-right">
                <button type="button" className="btn btn-back">
                  <i className="fas fa-undo mr-1" />
                  Back
                </button>{" "}
                <button type="submit" className="btn btn-primary">
                  submit
                  <i className="fas fa-sign-in-alt ml-1" />
                </button>
              </div>
            </div>
          </form>
          <p style={{ color: "red" }}>{error}</p>
        </div>
        {/**/}
      </div>


      <div
        id="__BVID__104___BV_modal_content_"
        tabIndex={-1}
        className="modal-content"
      >
        <header id="__BVID__103___BV_modal_header_" className="modal-header">
          <h4 className="modal-title">withdraw</h4>{" "}
          <button onClick={closeHandler} type="button" data-dismiss="modal" className="close text-white">
            ×
          </button>
        </header>
        <div id="__BVID__103___BV_modal_body_" className="modal-body">
          {" "}
          {/**/}{" "}
          <form onSubmit={withdrawHandler} data-vv-scope="userdepositeMDL" method="post">
            <div className="form-group row">
              <label className="col-form-label col-4">{curentuser.Creator}</label>{" "}
              <div className="col-8">
                <div className="row">
                  <div className="col-6">
                    <input
                      placeholder="Amount"
                      type="text"
                      readOnly="readonly"
                      name="userDipositeloginusramount"
                      className="form-control txt-right"
                      value={user.AvailableBalance}
                    />
                  </div>{" "}
                  <div className="col-6">
                    <input
                      placeholder="Amount"
                      type="text"
                      readOnly="readonly"
                      name="userDipositeloginusrNamount"
                      className="form-control txt-right"
                      value={user.AvailableBalance}
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-form-label col-4">{curentuser.username}</label>{" "}
              <div className="col-8">
                <div className="row">
                  <div className="col-6">
                    <input
                      placeholder="Amount"
                      type="text"
                      readOnly="readonly"
                      name="userDipositeusrnameamount"
                      className="form-control txt-right"
                      value={curentuser.AvailableBalance}
                    />
                  </div>{" "}
                  <div className="col-6">
                    <input
                      placeholder="Amount"
                      type="text"
                      readOnly="readonly"
                      name="userDipositeusrnameNamount"
                      className="form-control txt-right"
                      value={curentuser.AvailableBalance}
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-form-label col-4">Amount</label>{" "}
              <div className="col-8 form-group-feedback form-group-feedback-right">
                <input
                  placeholder="Amount"
                  type="number"
                  name="userDipositeamount"
                  className="form-control txt-right"
                  aria-required="true"
                  aria-invalid="false"
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-form-label col-4">Remark</label>{" "}
              <div className="col-8 form-group-feedback form-group-feedback-right">
                <textarea
                  placeholder="Remark"
                  name="userDipositeremark"
                  className="form-control"
                  aria-required="true"
                  aria-invalid="false"
                  defaultValue={""}
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-form-label col-4">Transaction Password</label>{" "}
              <div className="col-8 form-group-feedback form-group-feedback-right">
                <input
                  name="userDipositempassword"
                  type="password"
                  className="form-control"
                  aria-required="true"
                  aria-invalid="false"
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <div className="col-12 text-right">
                <button type="button" className="btn btn-back">
                  <i className="fas fa-undo mr-1" />
                  Back
                </button>{" "}
                <button type="submit" className="btn btn-primary">
                  submit
                  <i className="fas fa-sign-in-alt ml-1" />
                </button>
              </div>
            </div>
            <p style={{ color: "red" }}>{error}</p>
          </form>
        </div>
        {/**/}
      </div>


      <div
        id="__BVID__103___BV_modal_content_"
        tabIndex={-1}
        className="modal-content"
      >
        <header id="__BVID__103___BV_modal_header_" className="modal-header">
          <h4 className="modal-title">Deposit</h4>{" "}
          <button onClick={closeHandler} type="button" data-dismiss="modal" className="close text-white">
            ×
          </button>
        </header>
        <div id="__BVID__103___BV_modal_body_" className="modal-body">
          {" "}
          {/**/}{" "}
          <form onSubmit={depositHandler} data-vv-scope="userdepositeMDL" method="post">
            <div className="form-group row">
              <label className="col-form-label col-4">{curentuser.Creator}</label>{" "}
              <div className="col-8">
                <div className="row">
                  <div className="col-6">
                    <input
                      placeholder="Amount"
                      type="text"
                      readOnly="readonly"
                      name="userDipositeloginusramount"
                      className="form-control txt-right"
                      value={user.AvailableBalance}
                    />
                  </div>{" "}
                  <div className="col-6">
                    <input
                      placeholder="Amount"
                      type="text"
                      readOnly="readonly"
                      name="userDipositeloginusrNamount"
                      className="form-control txt-right"
                      value={user.AvailableBalance}
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-form-label col-4">{curentuser.username}</label>{" "}
              <div className="col-8">
                <div className="row">
                  <div className="col-6">
                    <input
                      placeholder="Amount"
                      type="text"
                      readOnly="readonly"
                      name="userDipositeusrnameamount"
                      className="form-control txt-right"
                      value={curentuser.AvailableBalance}
                    />
                  </div>{" "}
                  <div className="col-6">
                    <input
                      placeholder="Amount"
                      type="text"
                      readOnly="readonly"
                      name="userDipositeusrnameNamount"
                      className="form-control txt-right"
                      value={curentuser.AvailableBalance}
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-form-label col-4">Amount</label>{" "}
              <div className="col-8 form-group-feedback form-group-feedback-right">
                <input
                  placeholder="Amount"
                  type="number"
                  name="userDipositeamount"
                  className="form-control txt-right"
                  aria-required="true"
                  aria-invalid="false"
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-form-label col-4">Remark</label>{" "}
              <div className="col-8 form-group-feedback form-group-feedback-right">
                <textarea
                  placeholder="Remark"
                  name="userDipositeremark"
                  className="form-control"
                  aria-required="true"
                  aria-invalid="false"
                  defaultValue={""}
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-form-label col-4">Transaction Password</label>{" "}
              <div className="col-8 form-group-feedback form-group-feedback-right">
                <input
                  name="userDipositempassword"
                  type="password"
                  className="form-control"
                  aria-required="true"
                  aria-invalid="false"
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <div className="col-12 text-right">
                <button type="button" className="btn btn-back">
                  <i className="fas fa-undo mr-1" />
                  Back
                </button>{" "}
                <button type="submit" className="btn btn-primary">
                  submit
                  <i className="fas fa-sign-in-alt ml-1" />
                </button>
              </div>
            </div>
            <p style={{ color: "red" }}>{error}</p>
          </form>
        </div>
        {/**/}
      </div>


      <div data-v-61537a09="" className="listing-grid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Account List</h4>{" "}
              <div className="page-title-right">
                <div className="d-inline-block">
                  <Link to="/admin/insertUser" style={{ backgroundColor: "#ff00a2", color: "white" }} className="btn">
                    {" "}
                    Add Account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="row account-list">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row row5">
                  <div className="col-md-6 mb-2 search-form">
                    <div className="d-inline-block mr-2">
                      <button type="button" className="btn buttons-pdf btn-danger">
                        <i className="far fa-file-pdf mr-1" />
                        PDF
                      </button>{" "}
                      <div id="export_1724783905160" className="d-inline-block">
                        <button
                          type="button"
                          className="btn buttons-excel btn-success"
                        >
                          <i className="far fa-file-excel mr-1" />
                          Excel
                        </button>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-6 text-right mb-2" />
                </div>{" "}
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div id="tickets-table_length" className="dataTables_length">
                      <label className="d-inline-flex align-items-center">
                        Show&nbsp;
                        <select
                          className="custom-select custom-select-sm"
                          id="__BVID__136"
                        >

                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                          <option value={250}>250</option>
                          <option value={500}>500</option>
                          <option value={750}>750</option>
                          <option value={1000}>1000</option>

                        </select>
                        &nbsp;entries
                      </label>
                    </div>
                  </div>{" "}


                  <div className="col-sm-12 col-md-6">

                    <div
                      id="tickets-table_filter"
                      className="dataTables_filter text-md-right"
                    >
                      <label className="d-inline-flex align-items-center">
                        Search:
                        <input
                          name="searchuser"
                          type="search"
                          placeholder="Search..."
                          className="form-control form-control-sm ml-2 form-control"
                          id="__BVID__137"
                        />{" "}
                        <button
                          type="submit"
                          id="loaddata"

                          style={{ backgroundColor: "#ff00a2", color: "white" }}
                          className="btn"
                        >
                          Load
                        </button>{" "}
                        <button
                          type="submit"
                          id="loaddata"
                          className="btn btn-secondary ml-2"
                        >
                          Reset
                        </button>
                      </label>
                    </div>
                  </div>
                </div>{" "}

                <div className="tabs" id="__BVID__2661">
                  {/**/}
                  <div className="">
                    <ul
                      role="tablist"
                      className="nav nav-tabs"
                      id="__BVID__2661__BV_tab_controls_"
                    >
                      {/**/}
                      <li role="presentation" className="nav-item">
                        <a
                          role="tab"
                          aria-selected="true"
                          aria-setsize={2}
                          aria-posinset={1}
                          href="#"
                          target="_self"
                          className="nav-link active tab-bg-warning"
                          id="__BVID__2662___BV_tab_button__"
                          aria-controls="__BVID__2662"
                        >
                          Active Users
                        </a>
                      </li>
                      <li role="presentation" className="nav-item">
                        <a
                          role="tab"
                          tabIndex={-1}
                          aria-selected="false"
                          aria-setsize={2}
                          aria-posinset={2}
                          href="#"
                          target="_self"
                          className="nav-link"
                          id="__BVID__2664___BV_tab_button__"
                          aria-controls="__BVID__2664"
                        >
                          Deactive Users
                        </a>
                      </li>
                      {/**/}
                    </ul>
                  </div>
                  <div className="tab-content text-muted" id="__BVID__2661__BV_tab_container_">
                    <div
                      role="tabpanel"
                      aria-hidden="false"
                      className="tab-pane active"
                      id="__BVID__2662"
                      aria-labelledby="__BVID__2662___BV_tab_button__"
                    />{" "}
                    <div
                      role="tabpanel"
                      aria-hidden="true"
                      className="tab-pane"
                      id="__BVID__2664"
                      aria-labelledby="__BVID__2664___BV_tab_button__"
                      style={{ display: "none" }}
                    />
                    {/**/}
                  </div>
                </div>


                <div className="row pt-3">
                  <div className="col">

                  </div>
                </div>{" "}
                <div className="table no-footer list-clients table-responsive-sm">
                  <table
                    id="eventsListTbl"
                    role="table"
                    aria-busy="false"
                    aria-colcount={12}
                    className="table b-table table-striped table-bordered"
                  >
                    {/**/}
                    {/**/}
                    <thead role="rowgroup" className="">
                      {/**/}
                      <tr role="row" className="">
                        <th
                          role="columnheader"
                          scope="col"
                          tabIndex={0}
                          aria-colindex={1}
                          aria-sort="none"
                          className="position-relative"
                        >
                          <div>User Name</div>
                          <span className="sr-only"> (Click to sort ascending)</span>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          tabIndex={0}
                          aria-colindex={2}
                          aria-sort="none"
                          className="position-relative text-right"
                        >
                          <div>Credit Referance</div>
                          <span className="sr-only"> (Click to sort ascending)</span>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          tabIndex={0}
                          aria-colindex={3}
                          aria-sort="none"
                          className="position-relative text-right"
                        >
                          <div>Balance</div>
                          <span className="sr-only"> (Click to sort ascending)</span>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          tabIndex={0}
                          aria-colindex={4}
                          aria-sort="none"
                          className="position-relative text-right"
                        >
                          <div>Client(P/L)</div>
                          <span className="sr-only"> (Click to sort ascending)</span>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex={5}
                          className="text-right"
                        >
                          <div>Exposure</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex={6}
                          className="text-right"
                        >
                          <div>Available Balance</div>
                        </th>
                        <th role="columnheader" scope="col" aria-colindex={7} className="">
                          <div>U st</div>
                        </th>
                        <th role="columnheader" scope="col" aria-colindex={8} className="">
                          <div>B st</div>
                        </th>
                        <th role="columnheader" scope="col" aria-colindex={9} className="">
                          <div>Exposure Limit</div>
                        </th>
                        <th role="columnheader" scope="col" aria-colindex={10} className="">
                          <div>Default(%)</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          tabIndex={0}
                          aria-colindex={11}
                          aria-sort="none"
                          className="position-relative"
                        >
                          <div>Account Type</div>
                          <span className="sr-only"> (Click to sort ascending)</span>
                        </th>
                        <th role="columnheader" scope="col" aria-colindex={12} className="">
                          <div>Action</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      {/**/}
                      <tr role="row" className="">
                        <td aria-colindex={1} role="cell" className="" />
                        <td aria-colindex={2} role="cell" className="">
                          <p className="text-right mb-0 cp">0</p>
                        </td>
                        <td aria-colindex={3} role="cell" className="">
                          <p className="text-right mb-0">0</p>
                        </td>
                        <td aria-colindex={4} role="cell" className="">
                          <p className="text-right mb-0">0</p>
                        </td>
                        <td aria-colindex={5} role="cell" className="text-right">
                          <a href="javascript:void(0)" className="text-right mb-0" />
                        </td>
                        <td aria-colindex={6} role="cell" className="">
                          <p className="text-right mb-0">0</p>
                        </td>
                        <td aria-colindex={7} role="cell" className="" />
                        <td aria-colindex={8} role="cell" className="" />
                        <td aria-colindex={9} role="cell" className="" />
                        <td aria-colindex={10} role="cell" className="">
                          <p className="text-left mb-0"></p>
                        </td>
                        <td aria-colindex={11} role="cell" className="" />
                        <td aria-colindex={12} role="cell" className="">
                          <div role="group" className="btn-group">
                            {/**/}
                          </div>
                        </td>
                      </tr>


                      {creatorUsers.map((elem) => (

                        <>


                          <tr role="row" className="" k>
                            <td aria-colindex={1} role="cell" className="">
                              <span title="" className="wrape-text">
                                {elem.username}
                              </span>
                            </td>
                            <td aria-colindex={2} role="cell" className="">
                              <p className="text-right mb-0 cp">{elem.creditReference}</p>
                            </td>
                            <td aria-colindex={3} role="cell" className="">
                              <p className="text-right mb-0">{elem.Default}</p>
                            </td>
                            <td aria-colindex={4} role="cell" className="">
                              <p className="text-right mb-0">{elem.ClientPL}</p>
                            </td>
                            <td aria-colindex={5} role="cell" className="text-right">
                              <p className="mb-0 text-right">{elem.Exposure}</p>
                            </td>
                            <td aria-colindex={6} role="cell" className="">
                              <p className="text-right mb-0">{elem.AvailableBalance}</p>
                            </td>
                            <td aria-colindex={7} role="cell" className="">
                              <div className="mb-1 custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  disabled="disabled"
                                  className="custom-control-input"
                                  defaultValue="true"
                                  id="__BVID__123"
                                />
                                <label className="custom-control-label" htmlFor="__BVID__123" />
                              </div>
                            </td>
                            <td aria-colindex={8} role="cell" className="">
                              <div className="mb-1 custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  disabled="disabled"
                                  className="custom-control-input"
                                  defaultValue="true"
                                  id="__BVID__124"
                                />
                                <label className="custom-control-label" htmlFor="__BVID__124" />
                              </div>
                            </td>
                            <td aria-colindex={9} role="cell" className="">
                              {elem.ExposureLimit}
                            </td>
                            <td aria-colindex={10} role="cell" className="">
                              <p className="text-left mb-0">{elem.Default}</p>
                            </td>
                            <td aria-colindex={11} role="cell" className="">
                              {elem.accountType}
                            </td>
                            <td aria-colindex={12} role="cell" className="">
                              <div role="group" className="btn-group">
                                <button data-userid={elem.username} onClick={depositButtonHandler} type="button" className="btn action-button btn-secondary">
                                  D
                                </button>{" "}
                                <button data-userid={elem.username} onClick={WButtonHandler} type="button" className="btn action-button btn-secondary">
                                  W
                                </button>{" "}
                                <button type="button" data-userid={elem.username} onClick={LButtonHandler} className="btn action-button btn-secondary">
                                  L
                                </button>{" "}
                                <button data-userid={elem.username} onClick={cButtonHandler} type="button" className="btn action-button btn-secondary">
                                  C
                                </button>{" "}
                                <button type="button" className="btn action-button btn-secondary">
                                  P
                                </button>{" "}
                                <button type="button" className="btn action-button btn-secondary">
                                  S
                                </button>
                              </div>
                            </td>
                          </tr>


                        </>
                      ))}









                      {/**/}
                      {/**/}
                    </tbody>
                    {/**/}
                  </table>
                </div>

                {/**/} {/**/} {/**/} {/**/} {/**/} {/**/} {/**/} {/**/}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
