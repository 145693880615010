import axios from 'axios';

// Create an instance of axios with default settings
//let url = 'https://servermatkafinal.onrender.com/api';http://15.207.192.222:5000/api
//http://localhost:5000/api ssh -i "matkakey.pem" ubuntu@ec2-15-207-192-222.ap-south-1.compute.amazonaws.com


const axiosInstance = axios.create({
  baseURL: 'http://15.207.192.222:5000/api', // Your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;
