import React, { useContext } from 'react'
import Admin from './Admin'
import { UserContext } from '../context/UserContext';

export default function AccountStatment() {
  const { user, updateUser } = useContext(UserContext);





  const combinedHistory = [
    ...user.depositHistory.map(deposit => ({
      ...deposit,
      type: 'deposit', // Add a type field for identification
    })),
    ...user.withdrawHistory.map(withdraw => ({
      ...withdraw,
      type: 'withdraw', // Add a type field for identification
    })),
  ].sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date ascending

  return (
    <div>

      <Admin></Admin>


      <div data-v-61537a09="">
        <div data-v-61537a09="" className="listing-grid report-wrapper">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Account Statement</h4>{" "}
                <div className="page-title-right">{/**/}</div>
              </div>
            </div>
          </div>{" "}
          <div className="report-form mb-0 mt-3">
            <form method="post" className="ajaxFormSubmit">
              <div className="row">
                <div className="col-lg-2">
                  <div className="form-group">
                    <label>Account Type</label>{" "}
                    <select className="form-control">
                      {/* <option value={0}>All</option>{" "} */}
                      <option value={1}>Deposit/Withdraw Report</option>{" "}
                      {/* <option value={2}>Sports Report</option>{" "}
                      <option value={3}>Casino Report</option>{" "}
                      <option value={4}>Third Party Casino Report</option> */}
                    </select>
                  </div>
                </div>{" "}
                {/**/}{" "}
                <div className="col-lg-2">
                  <div className="form-group">
                    <label>Game Name</label>{" "}
                    <select className="form-control">
                      <option value="allbalance">All</option>
                    </select>
                  </div>
                </div>{" "}
                {/**/}{" "}
                <div className="col-lg-2">
                  <div
                    className="form-group user-lock-search"
                    style={{ position: "relative" }}
                  >
                    <label htmlFor="d-inline-block">Search By Client Name</label>{" "}
                    <div
                      tabIndex={-1}
                      role="combobox"
                      aria-owns="listbox-null"
                      className="multiselect"
                    >
                      <div className="multiselect__select" />{" "}
                      <div className="multiselect__tags">
                        <div
                          className="multiselect__tags-wrap"
                          style={{ display: "none" }}
                        />{" "}
                        {/**/}{" "}
                        <div
                          className="multiselect__spinner"
                          style={{ display: "none" }}
                        />{" "}
                        <input
                          name=""
                          type="text"
                          autoComplete="off"
                          spellCheck="false"
                          placeholder="Select option"
                          tabIndex={0}
                          aria-controls="listbox-null"
                          className="multiselect__input"
                          style={{ width: 0, position: "absolute", padding: 0 }}
                        />{" "}
                        {/**/}{" "}
                        <span className="multiselect__placeholder">
                          Select option
                        </span>
                      </div>{" "}
                      <div
                        tabIndex={-1}
                        className="multiselect__content-wrapper"
                        style={{ maxHeight: 300, display: "none" }}
                      >
                        <ul
                          role="listbox"
                          id="listbox-null"
                          className="multiselect__content"
                          style={{ display: "block" }}
                        >
                          {" "}
                          {/**/}{" "}
                          <li style={{ display: "none" }}>
                            <span className="multiselect__option">
                              No elements found. Consider changing the search query.
                            </span>
                          </li>{" "}
                          <li>
                            <span className="multiselect__option">
                              List is empty.
                            </span>
                          </li>{" "}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                {/* <div className="col-lg-2">
                  <label>From</label>{" "}
                  <div className="mb-3 mx-datepicker">
                    <div className="mx-input-wrapper">
                      <input
                        name="date"
                        type="text"
                        autoComplete="off"
                        placeholder=""
                        className="mx-input"
                      />
                      <i className="mx-icon-calendar">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 1024 1024"
                          width="1em"
                          height="1em"
                        >
                          <path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z" />
                        </svg>
                      </i>
                    </div>
                  </div>
                </div>{" "} */}
                <div className="col-lg-2">
                  {/* <label>To</label>{" "} */}
                  <div className="mb-3 mx-datepicker">
                    {/* <div className="mx-input-wrapper">
                      <input
                        name="date"
                        type="text"
                        autoComplete="off"
                        placeholder=""
                        className="mx-input"
                      />
                      <i className="mx-icon-calendar">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 1024 1024"
                          width="1em"
                          height="1em"
                        >
                          <path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z" />
                        </svg>
                      </i>
                    </div> */}
                  </div>
                </div>
              </div>{" "}
              <div className="row">
                <div className="col-lg-2">
                  <button type="submit" id="loaddata" className="btn btn-primary">
                    Load
                  </button>
                </div>
              </div>
            </form>
          </div>{" "}
          <div className="row mt-3">
            <div className="col-6">
              {/**/}{" "}
              <div>
                <button type="button" className="btn buttons-pdf btn-danger">
                  <i className="far fa-file-pdf mr-1" />
                  PDF
                </button>{" "}
                <div id="export_1726956524727" className="d-inline-block">
                  <button type="button" className="btn buttons-excel btn-success">
                    <i className="far fa-file-excel mr-1" />
                    Excel
                  </button>
                </div>
              </div>
            </div>{" "}
            <div className="col-6 text-right">
              <div
                id="tickets-table_filter"
                className="dataTables_filter text-md-right"
              >
                <label className="d-inline-flex align-items-center">
                  Search:
                  <input
                    type="search"
                    placeholder="Search..."
                    className="form-control ml-1 form-control"
                    id="__BVID__138"
                  />
                </label>
              </div>
            </div>
          </div>{" "}
          <div className="table-responsive mb-0 mt-2 report-table account-table">
            <div className="table no-footer table-hover table-responsive-sm">
              <table
                id="accStmtTable"
                role="table"
                aria-busy="false"
                aria-colcount={6}
                className="table b-table table-striped table-bordered"
              >
                {/**/}
                <colgroup>
                  <col style={{ width: 120 }} />
                  <col style={{ width: 120 }} />
                  <col style={{ width: 120 }} />
                  <col style={{ width: 120 }} />
                  <col style={{ width: 350 }} />
                  <col style={{ width: 120 }} />
                </colgroup>
                <thead role="rowgroup" className="">
                  {/**/}
                  <tr role="row" className="">
                    <th
                      role="columnheader"
                      scope="col"
                      tabIndex={0}
                      aria-colindex={1}
                      aria-sort="none"
                      className="position-relative"
                    >
                      <div>Date</div>
                      <span className="sr-only"> (Click to sort ascending)</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabIndex={0}
                      aria-colindex={2}
                      aria-sort="none"
                      className="position-relative text-right"
                    >
                      <div>Credit</div>
                      <span className="sr-only"> (Click to sort ascending)</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabIndex={0}
                      aria-colindex={3}
                      aria-sort="none"
                      className="position-relative text-right"
                    >
                      <div>Debit</div>
                      <span className="sr-only"> (Click to sort ascending)</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabIndex={0}
                      aria-colindex={4}
                      aria-sort="none"
                      className="position-relative text-right"
                    >
                      <div>Closing</div>
                      <span className="sr-only"> (Click to sort ascending)</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabIndex={0}
                      aria-colindex={5}
                      aria-sort="none"
                      className="position-relative"
                    >
                      <div>Description</div>
                      <span className="sr-only"> (Click to sort ascending)</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabIndex={0}
                      aria-colindex={6}
                      aria-sort="none"
                      className="position-relative"
                    >
                      <div>Fromto</div>
                      <span className="sr-only"> (Click to sort ascending)</span>
                    </th>
                  </tr>
                </thead>
                <tbody role="rowgroup">


                  {combinedHistory.length > 0 ? (
                    combinedHistory.map((entry, index) => (
                      <tr key={index} role="row" tabIndex={0} aria-rowindex={index + 1} className="nocursor">
                        <td aria-colindex={1} role="cell" className="">
                          {new Date(entry.date).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}
                        </td>
                        <td aria-colindex={2} role="cell" className="">
                          {entry.type === 'withdraw' ? (
                            <div className="text-right text-success">
                              <span>{entry.amount}</span>
                            </div>
                          ) : (
                            <div className="text-right">
                              <span />
                            </div>
                          )}
                        </td>
                        <td aria-colindex={3} role="cell" className="">
                          {entry.type === 'deposit' ? (
                            <div className="text-right text-danger">
                              <span>{entry.amount}</span>
                            </div>
                          ) : (
                            <div className="text-right text-danger">
                              <span></span>
                            </div>
                          )}
                        </td>
                        <td aria-colindex={4} role="cell" className="">
                          <div className="text-right text-success">
                            <span>{entry.closingAmount}</span>
                          </div>
                        </td>
                        <td aria-colindex={5} role="cell" className="">
                          <div className="wrape-text">{entry.remark}</div>
                        </td>
                        <td aria-colindex={6} role="cell" className="">
                          {entry.from + " / " + user.username}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <li>No transactions found.</li>
                  )}



                  {/**/}
                  {/**/}
                </tbody>
                {/**/}
              </table>
            </div>
          </div>{" "}
          <div className="row pt-3">
            <div className="col-12">
              <div className="dataTables_paginate paging_simple_numbers float-right">
                <ul className="pagination pagination-rounded mb-0">
                  <ul
                    role="menubar"
                    aria-disabled="false"
                    aria-label="Pagination"
                    className="pagination dataTables_paginate paging_simple_numbers my-0 b-pagination justify-content-end"
                  >
                    <li
                      role="presentation"
                      aria-hidden="true"
                      className="page-item disabled"
                    >
                      <span
                        role="menuitem"
                        aria-label="Go to first page"
                        aria-disabled="true"
                        className="page-link"
                      >
                        «
                      </span>
                    </li>
                    <li
                      role="presentation"
                      aria-hidden="true"
                      className="page-item disabled"
                    >
                      <span
                        role="menuitem"
                        aria-label="Go to previous page"
                        aria-disabled="true"
                        className="page-link"
                      >
                        ‹
                      </span>
                    </li>
                    {/**/}
                    {/**/}
                    <li role="presentation" className="page-item active">
                      <button
                        role="menuitemradio"
                        type="button"
                        aria-label="Go to page 1"
                        aria-checked="true"
                        aria-posinset={1}
                        aria-setsize={1}
                        tabIndex={0}
                        className="page-link"
                      >
                        1
                      </button>
                    </li>
                    {/**/}
                    {/**/}
                    <li
                      role="presentation"
                      aria-hidden="true"
                      className="page-item disabled"
                    >
                      <span
                        role="menuitem"
                        aria-label="Go to next page"
                        aria-disabled="true"
                        className="page-link"
                      >
                        ›
                      </span>
                    </li>
                    <li
                      role="presentation"
                      aria-hidden="true"
                      className="page-item disabled"
                    >
                      <span
                        role="menuitem"
                        aria-label="Go to last page"
                        aria-disabled="true"
                        className="page-link"
                      >
                        »
                      </span>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>{" "}
          {/**/}
        </div>
      </div>
    </div>
  )
}
