import React, { useContext, useEffect, useState } from 'react'
import Admin from './Admin'
import { UserContext } from '../context/UserContext';
import axios from '../utils/axiosConfig';

export default function CurrentbetAdmin() {
    const { user, updateUser, setAlertt } = useContext(UserContext);
    const [creatorUsers, setCreatorUsers] = useState([]);

    const fetchUser = async () => {
        try {
            const response = await axios.post('/auth/users', { username: user.username });
            setCreatorUsers(response.data);
        } catch (error) {
            console.error(error);
        }
        console.log(creatorUsers); // This will log the initial state of creatorUsers, not the updated state.
    };

    // Non-async useEffect with internal async function
    useEffect(() => {
        const loadData = async () => {
            await fetchUser();
        };
        loadData();
    }, []);

    
    return (
        <div data-v-61537a09="" className="page-content">
            <Admin></Admin>
            {/**/}{" "}
            <div data-v-61537a09="">
                <div data-v-61537a09="" className="listing-grid report-wrapper">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0 font-size-18">Current Bets</h4>{" "}
                                <div className="page-title-right">{/**/}</div>
                            </div>
                        </div>
                    </div>{" "}
                    <div className="casino-report-tabs">
                        <ul className="nav nav-tabs">

                            <li className="nav-item">
                                <a
                                    data-toggle="tab"
                                    href="javascript:void(0)"
                                    className="nav-link active"
                                >
                                    Casino
                                </a>
                            </li>
                        </ul>
                    </div>{" "}
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="report-form mb-3 row align-items-center modal-chekbox">
                                        <div className="col-12">{/**/}</div>{" "}


                                    </div>{" "}
                                    <div className="row w-100">
                                        <div className="col-6">
                                            <div id="tickets-table_length" className="dataTables_length">
                                                <label className="d-inline-flex align-items-center">
                                                    Show&nbsp;
                                                    <select
                                                        className="custom-select custom-select-sm"
                                                        id="__BVID__66"
                                                    >
                                                        <option value={25}>25</option>
                                                        <option value={50}>50</option>
                                                        <option value={75}>75</option>
                                                        <option value={100}>100</option>
                                                        <option value={125}>125</option>
                                                        <option value={150}>150</option>
                                                    </select>
                                                    &nbsp;entries
                                                </label>
                                            </div>
                                        </div>{" "}
                                        <div className="col-6 text-right">
                                            <div
                                                id="tickets-table_filter"
                                                className="dataTables_filter text-md-right"
                                            >
                                                <label className="d-inline-flex align-items-center">
                                                    Search:
                                                    <input
                                                        type="search"
                                                        placeholder="Search..."
                                                        className="form-control ml-1 form-control"
                                                        id="__BVID__67"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>{" "}
                                    <div className="table-responsive mb-0 report-table">
                                        <div className="table no-footer table-responsive-sm">
                                            <table
                                                id="eventsListTbl"
                                                role="table"
                                                aria-busy="false"
                                                aria-colcount={6}
                                                className="table b-table table-striped table-bordered"
                                            >
                                                {/**/}
                                                <colgroup>
                                                    <col style={{ width: 200 }} />
                                                    <col style={{ width: "auto" }} />
                                                    <col style={{ width: "auto" }} />
                                                    <col style={{ width: "auto" }} />
                                                    <col style={{ width: "auto" }} />
                                                    <col style={{ width: "auto" }} />
                                                </colgroup>
                                                <thead role="rowgroup" className="">
                                                    {/**/}
                                                    <tr role="row" className="">
                                                        <th
                                                            role="columnheader"
                                                            scope="col"
                                                            tabIndex={0}
                                                            aria-colindex={1}
                                                            aria-sort="none"
                                                            className="position-relative"
                                                        >
                                                            <div>Event Name</div>
                                                            <span className="sr-only">
                                                                {" "}
                                                                (Click to sort ascending)
                                                            </span>
                                                        </th>
                                                        <th
                                                            role="columnheader"
                                                            scope="col"
                                                            tabIndex={0}
                                                            aria-colindex={2}
                                                            aria-sort="none"
                                                            className="position-relative"
                                                        >
                                                            <div>User Name</div>
                                                            <span className="sr-only">
                                                                {" "}
                                                                (Click to sort ascending)
                                                            </span>
                                                        </th>
                                                        <th
                                                            role="columnheader"
                                                            scope="col"
                                                            tabIndex={0}
                                                            aria-colindex={3}
                                                            aria-sort="none"
                                                            className="position-relative"
                                                        >
                                                            <div>Nation</div>
                                                            <span className="sr-only">
                                                                {" "}
                                                                (Click to sort ascending)
                                                            </span>
                                                        </th>
                                                        <th
                                                            role="columnheader"
                                                            scope="col"
                                                            tabIndex={0}
                                                            aria-colindex={4}
                                                            aria-sort="none"
                                                            className="position-relative text-right"
                                                        >
                                                            <div>User Rate</div>
                                                            <span className="sr-only">
                                                                {" "}
                                                                (Click to sort ascending)
                                                            </span>
                                                        </th>
                                                        <th
                                                            role="columnheader"
                                                            scope="col"
                                                            tabIndex={0}
                                                            aria-colindex={5}
                                                            aria-sort="none"
                                                            className="position-relative text-right"
                                                        >
                                                            <div>Amount</div>
                                                            <span className="sr-only">
                                                                {" "}
                                                                (Click to sort ascending)
                                                            </span>
                                                        </th>
                                                        <th
                                                            role="columnheader"
                                                            scope="col"
                                                            tabIndex={0}
                                                            aria-colindex={6}
                                                            aria-sort="none"
                                                            className="position-relative"
                                                        >
                                                            <div>Place Date</div>
                                                            <span className="sr-only">
                                                                {" "}
                                                                (Click to sort ascending)
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody role="rowgroup">
                                                    {/**/}





                                                    {creatorUsers.map((userr, index) => (
                                                        <>
                                                            {userr.currentBet && userr.currentBet.length > 0 ? ( // Check if currentBet exists and is not empty
                                                                <>
                                                                    {userr.currentBet.map((bet, betIndex) => (
                                                                        <tr key={betIndex} role="row" aria-rowindex={betIndex + 1} className="back-border">
                                                                            <td aria-colindex={1} role="cell">
                                                                                {bet.remark}
                                                                            </td>
                                                                            <td aria-colindex={2} role="cell">
                                                                                {userr.username}
                                                                            </td>
                                                                            <td aria-colindex={3} role="cell">
                                                                                <p className="mb-0">{bet.nation}</p>
                                                                            </td>
                                                                            <td aria-colindex={4} role="cell">
                                                                                <p className="text-right mb-0">{bet.rate}</p>
                                                                            </td>
                                                                            <td aria-colindex={5} role="cell">
                                                                                <p className="text-right mb-0">{bet.amount.toFixed(2)}</p>
                                                                            </td>
                                                                            <td aria-colindex={6} role="cell">
                                                                                <div>{new Date(bet.date).toLocaleString()}</div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p>No current bets.</p>
                                                            )}
                                                       </>
                                                    ))}












                                                    {/**/}
                                                    {/**/}
                                                </tbody>
                                                {/**/}
                                            </table>
                                        </div>
                                    </div>{" "}
                                    <div className="row pt-3">
                                        <div className="col">
                                            <div className="dataTables_paginate paging_simple_numbers float-right">
                                                <ul className="pagination pagination-rounded mb-0">
                                                    <ul
                                                        role="menubar"
                                                        aria-disabled="false"
                                                        aria-label="Pagination"
                                                        className="pagination dataTables_paginate paging_simple_numbers my-0 b-pagination justify-content-end"
                                                    >
                                                        <li
                                                            role="presentation"
                                                            aria-hidden="true"
                                                            className="page-item disabled"
                                                        >
                                                            <span
                                                                role="menuitem"
                                                                aria-label="Go to first page"
                                                                aria-disabled="true"
                                                                className="page-link"
                                                            >
                                                                «
                                                            </span>
                                                        </li>
                                                        <li
                                                            role="presentation"
                                                            aria-hidden="true"
                                                            className="page-item disabled"
                                                        >
                                                            <span
                                                                role="menuitem"
                                                                aria-label="Go to previous page"
                                                                aria-disabled="true"
                                                                className="page-link"
                                                            >
                                                                ‹
                                                            </span>
                                                        </li>
                                                        {/**/}
                                                        {/**/}
                                                        <li role="presentation" className="page-item active">
                                                            <button
                                                                role="menuitemradio"
                                                                type="button"
                                                                aria-label="Go to page 1"
                                                                aria-checked="true"
                                                                aria-posinset={1}
                                                                aria-setsize={1}
                                                                tabIndex={0}
                                                                className="page-link"
                                                            >
                                                                1
                                                            </button>
                                                        </li>
                                                        {/**/}
                                                        {/**/}
                                                        <li
                                                            role="presentation"
                                                            aria-hidden="true"
                                                            className="page-item disabled"
                                                        >
                                                            <span
                                                                role="menuitem"
                                                                aria-label="Go to next page"
                                                                aria-disabled="true"
                                                                className="page-link"
                                                            >
                                                                ›
                                                            </span>
                                                        </li>
                                                        <li
                                                            role="presentation"
                                                            aria-hidden="true"
                                                            className="page-item disabled"
                                                        >
                                                            <span
                                                                role="menuitem"
                                                                aria-label="Go to last page"
                                                                aria-disabled="true"
                                                                className="page-link"
                                                            >
                                                                »
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                    {/**/}
                </div>
            </div>
        </div>

    )
}
