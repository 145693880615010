import React, { useContext, useState } from 'react'
import { UserContext } from '../context/UserContext';

export default function AccBl() {

  const { user , updateUser} = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(true)

  const clickHandler = () => {
    if (isOpen) {
      document.querySelector(".master-balance").style.height = "50px"
      setIsOpen(false)
    } else {
      document.querySelector(".master-balance").style.height = "unset"
      setIsOpen(true)
    }
  }
  return (
    <div>
      <div style={{ marginTop: "23px", background: "#ff00a2" ,height:"50px",left:"0px" ,width:"100%" }} className="master-balance">
        <div className="text-center">
          <span onClick={clickHandler} id="user-balance" className="far fa-arrow-alt-circle-up" />
        </div>{" "}
        <div id="master-balance-detail" className="master-balance-detail mt-3">
          <ul className="row">
            <li className="col-md-4">
              <label className="col-md-8 text-left">
                Upper Level Credit Referance:
              </label>{" "}
              <span className="text-right col-md-4">{user ? user.UpperLevelCreditReference : ""}</span>
            </li>{" "}
            <li className="col-md-4">
              <label className="col-md-8 text-left">Down level Occupy Balance:</label>{" "}
              <span className="text-right col-md-4">{user ? user.DownlevelOccupyBalance :""}</span>
            </li>{" "}
            <li className="col-md-4">
              <label className="col-md-8 text-left">
                Down Level Credit Referance:
              </label>{" "}
              <span className="text-right col-md-4">0</span>
            </li>{" "}
            <li className="col-md-4">
              <label className="col-md-8 text-left">Total Master Balance</label>{" "}
              <span className="text-right col-md-4">{user ? user.DownlevelOccupyBalance + user.AvailableBalance :" "}</span>
            </li>{" "}
            <li className="col-md-4">
              <label className="col-md-8 text-left">Upper Level:</label>{" "}
              <span className="text-right col-md-4">0</span>
            </li>{" "}
            <li className="col-md-4">
              <label className="col-md-8 text-left">Down Level Profit/Loss :</label>{" "}
              <span className="text-right col-md-4">0</span>
            </li>{" "}
            <li className="col-md-4">
              <label className="col-md-8 text-left">Available Balance:</label>{" "}
              <span className="text-right col-md-4">{user ? user.AvailableBalance : ""}</span>
            </li>{" "}
            <li className="col-md-4">
              <label className="col-md-8 text-left">
                Available Balance With Profit/Loss:
              </label>{" "}
              <span className="text-right col-md-4">{user ? user.creditReference : ""}</span>
            </li>{" "}
            <li className="col-md-4">
              <label className="col-md-8 text-left">My Profit/Loss:</label>{" "}
              <span className="text-right col-md-4">0</span>
            </li>
          </ul>
        </div>
      </div>

    </div>
  )
}
