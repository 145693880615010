import React from 'react'
import Admin from './Admin'

export default function Market() {
  return (
    <div>
      <Admin></Admin>
    </div>
  )
}
