import React, { useContext, useState } from 'react';
import { UserContext } from '../context/UserContext';
import AccountList from './AccountList';
import { Link } from 'react-router-dom';
import matka from '../Images/matka.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';

export default function Admin() {
  const { user , alertt, setAlertt,} = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false)
  const notify = () => {
    toast.success('Success !', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  const clickHandlerClose = () => {
    document.getElementById("sidebar-menu").style.display = "none"
    console.log("in hnd")
  }
  const openSliderHandler = () => {
    document.getElementById("sidebar-menu").style.display = "block"
  }

  const clickHandler = () => {
    if (!isOpen) {
      document.getElementById('signoutmenu').style.display = 'block';
      setIsOpen(true)
    } else {
      document.getElementById('signoutmenu').style.display = 'none';
      setIsOpen(false)
    }

  }


  useEffect(() => {
    
      

      if(alertt){
          notify()
          setAlertt(false)
      }
      
      
   
    
    
  }, [user])


  return (
    <>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <title>GRANDEXCH</title>
      <meta charSet="utf-8" />
      <meta name="theme-color" content="" />
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.7.0/css/all.css"
      />
      <link
        rel="stylesheet"
        href="https://versionobj.ecoassetsservice.com/v15/static/admin/css/app.css"
      />
      <link
        rel="stylesheet"
        href="https://versionobj.ecoassetsservice.com/v15/static/front/plugin/owl/assets/owl.carousel.min.css"
      />
      <link
        rel="stylesheet"
        href="https://versionobj.ecoassetsservice.com/v15/static/front/plugin/owl/assets/owl.theme.default.min.css"
      />
      <link
        rel="stylesheet"
        href="https://versionobj.ecoassetsservice.com/v15/static/admin/css/flipclock.css"
      />
      <link
        rel="stylesheet"
        href="https://versionobj.ecoassetsservice.com/v15/static/admin/css/style.css"
      />
      <link
        rel="stylesheet"
        href="https://sitethemedata.com/sitethemes/grandexch.com/front/theme.css"
      />
      <link
        rel="stylesheet"
        href="https://versionobj.ecoassetsservice.com/v15/static/admin/css/custom.css"
      />
      <link
        rel="stylesheet"
        href="https://versionobj.ecoassetsservice.com/v15/static/admin/css/responsive.css"
      />
      <div id="app">
        <ul
          id='signoutmenu'
          role="menu"
          tabIndex={-1}
          className="divlogoutwala"
          aria-labelledby="__BVID__57__BV_toggle_"
          x-placement="bottom-start"
          style={{ display: "none", background: "#ff00a2" }}
        >

          <a style={{ color: "white", fontWeight: "700" }} href="" className="dropdown-item">
            Secure Auth Verification
          </a>{" "}
          <a style={{ color: "white", fontWeight: "700" }} href="" className="dropdown-item">
            Change Password
          </a>{" "}
          <a style={{ color: "white", fontWeight: "700" }} href='/' className="dropdown-item">
            Logout
          </a>
        </ul>
        <header style={{ width: "100vw !important", marginTop: "-1px" }} data-v-61537a09="" id="page-topbar">
          <div className="navbar-header" style={{ background: "#ff00a2", height: "unset", minHeight: "50px", minWidth: "50rem" }}>
            <div className="d-flex">

              <div className="navbar-brand-box">

              </div>{" "}
              <button
                id="vertical-menu-btn"
                type="button"
                className="btn btn-sm px-3 font-size-16 header-item"

              >
                <Link style={{ fontWeight: "900", color: "#ffff" }} to={"/admin2"}>
                  <div style={{ width: "149px" }} className="f-pti">
                    <h1 style={{ color: "#fff", display: "flex", alignItems: "center", justifyContent: "center" }}> <img style={{ height: "50px" }} src={matka} alt="" srcset="" />Matka Boss</h1>
                  </div>
                </Link>
              </button>{" "}
              <li><i style={{ marginTop: "40px", width: "50px" }} onClick={openSliderHandler} className="fa fa-fw fa-bars" /></li>
              <ul className="nav align-items-center main-menu">

                <li className="nav-item">
                  <Link
                    to={"/admin/accountList"}
                    className="nav-link router-link-exact-active router-link-active"
                    aria-current="page"
                    style={{ color: "#fff", fontStyle: "normal", fontWeight: "normal" }}
                  >
                    List of Clients
                  </Link>
                </li>{" "}
                <li className="nav-item">
                  <Link to={"/admin/market"} style={{ color: "#fff", fontStyle: "normal", fontWeight: "normal" }} href="/admin/market" className="nav-link">
                    Market Analysis
                  </Link>
                </li>{" "}
                <li style={{ fontStyle: "none", fontWeight: "normal" }} className="nav-item dropdown">
                  <a
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-expanded="false"
                    className="nav-link dropdown-toggle"
                    style={{ color: "#fff", fontStyle: "normal", fontWeight: "normal" }}
                  >
                    Reports
                    <i className="fa fa-caret-down" />
                  </a>{" "}
                  <div style={{ background: "#ff00a2" }} className="dropdown-menu">
                    <Link
                      to={"/admin/accountStatment"}
                      className="side-nav-link-ref dropdown-item"
                      style={{ color: "#fff", fontStyle: "normal", fontWeight: "normal" }}
                    >
                      Account Statement
                    </Link>{" "}
                    <Link
                      to={"/admin/reports/currentbets"}
                      className="side-nav-link-ref dropdown-item"
                      style={{ color: "#fff", fontStyle: "normal", fontWeight: "normal" }}
                    >
                      Current Bets
                    </Link>{" "}

                    <Link
                      to={"/admin/reports/gamereport"}
                      className="side-nav-link-ref dropdown-item"
                      style={{ color: "#fff", fontStyle: "normal", fontWeight: "normal" }}
                    >
                      Game Report
                    </Link>{" "}
                    {/* <a
                      href="/admin/reports/profitloss"
                      className="side-nav-link-ref dropdown-item"
                      style={{ color: "#fff", fontStyle: "normal", fontWeight: "normal" }}
                    >
                      Profit And Loss
                    </a>{" "} */}
                    {/**/} {/**/} {/**/}{" "}
                    <a
                      href="/admin/reports/authlist"
                      className="side-nav-link-ref dropdown-item d-none"
                    >
                      User Authentication
                    </a>
                  </div>
                </li>


                {" "}
                {/**/} {/**/}
              </ul>
            </div>{" "}
            <div className="d-flex user-menu">
              <div className="dropdown b-dropdown btn-group" id="__BVID__56">
                {/**/}
                <button
                  aria-haspopup="menu"
                  aria-expanded="false"
                  type="button"
                  className="btn dropdown-toggle btn-black header-item"
                  id="__BVID__56__BV_toggle_"
                >
                  <span className="ml-1">{user ? user.username : "none user"}</span>{" "}
                  <i onClick={clickHandler} className="fa fa-caret-down" />
                </button>
                <ul
                  role="menu"
                  tabIndex={-1}
                  className="dropdown-menu"
                  aria-labelledby="__BVID__56__BV_toggle_"
                  style={{ background: "#ff00a2" }}
                >
                  <div className="dropdown d-sm-none ml-1 mr-1">
                    <div className="bal-box">
                      <span className="balance nowrap">
                        Pts:
                        <span className="balance-value">
                          <b>39,36,766.05</b>
                        </span>{" "}
                        {/**/}
                      </span>
                    </div>
                  </div>{" "}
                  <a href="javascript: void(0);" className="dropdown-item d-sm-none">
                    <i className="fas fa-info-circle mr-1" /> Rules
                  </a>{" "}
                  <a href="/admin/secureauth" className="dropdown-item">
                    Secure Auth Verification
                  </a>{" "}
                  <a href="/admin/change-password" className="dropdown-item">
                    Change Password
                  </a>{" "}
                  <a href="javascript:void(0)" className="dropdown-item">
                    Logout
                  </a>
                </ul>
              </div>{" "}
              {/**/} {/**/}{" "}

            </div>
          </div>
        </header>
        <div id="sidebar-menu" style={{ display: "none" }}>
          <ul id="side-menu" className="metismenu list-unstyled">
            <li id="event-tree" className="menu-box">
              <a href="javascript: void(0);">
                <span>Sports</span> <i id='closeBtnslideBar' onClick={clickHandlerClose} className="fa fa-times float-right" />
              </a>{" "}
              <ul className="sub-menu mm-collapse mm-show">
                <li>
                  <span>
                    Kalyan <b className="ml-1"><button type="button" className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Open</button><button type="button" class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Close</button></b>
                  </span>{" "}
                  {/**/}
                </li>
                <li>
                  <span>
                    Kalyan Night <b className="ml-1"><button type="button" className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Open</button><button type="button" class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Close</button></b>
                  </span>{" "}
                  {/**/}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
