import { useState , useContext } from 'react';
import axios from '../utils/axiosConfig'; // Import your axios configuration
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';

const useLogin = () => {
  const { setUser,user ,setAlertt } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(true);
  const navigate = useNavigate();
  
  const login = async (credentials) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post('/auth/login', credentials); // API endpoint for login
    //   console.log(response.data.username)
    //   console.log(response.data.creditReference)
      setUser(response.data);
      setError(false)
      if (response.data.accountType === "admin") {
        await setAlertt(true);
        navigate('/admin');

      } else {
        navigate('/home');
      }
       // Set user data from response
    //   console.log(user)
    } catch (err) {
      setUser({})
      setError(true)
      console.log(err);
    } finally {
      setLoading(false);
    }


    console.log(user)

  };

  return { login, loading, error, user };
};

export default useLogin;
