// ProtectedRoute.js
import React from "react";
import { useState , useContext } from 'react';
import { Navigate } from "react-router-dom";
import { UserContext } from "./UserContext"; // Assuming UserContext is in the same folder

const ProtectedRoute = ({ children }) => {
  const { setUser,user ,setAlertt } = useContext(UserContext);
  // If user is not logged in, redirect to login page
  if (user.accountType !== "admin") {
    return <Navigate to="/" />;
  }

  // Otherwise, render the protected component
  return children;
};

export default ProtectedRoute;
