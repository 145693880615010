import React, { useState, useContext } from 'react';
import Admin from './Admin';
import { UserContext } from '../context/UserContext';
import useRegister from '../hooks/useRegister';


export default function InsertUser() {
  const { register, loading, er } = useRegister();
  
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    rpassword: '',
    fullname: '',
    city: '',
    phone: '',
    accountType: '',
    creditReference: '',
    mpassword: '',
  });
  const adpass = user.username; // Assuming adpass is the username of the admin
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (formData.password !== formData.rpassword) {
      setError('Passwords do not match.');
      return;
    }

    // Check if mpassword matches adpass
    if (formData.mpassword !== adpass) {
      setError('Transaction password does not match.');
      return;
    }


    setError('');
    const now = new Date().toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });

    const data = {
      "username": formData.username,
      "city": formData.city,
      "creditReference": formData.creditReference,
      "fullname": formData.fullname,
      "password": formData.password,
      "phone": formData.phone,
      "accountType": formData.accountType,
      "ClientPL": 0,
      "Exposure": 0,
      "AvailableBalance": 0,
      "Ust": true,
      "Bst": true,
      "ExposureLimit": 0,
      "Default": false,
      "Action" : [],
      "Creator":user.username
    }


    await register(data);
    console.log('Form Data:', data);
    // Add the code to create the user here
  };

  return (
    <div>
      <Admin />
      <div data-v-61537a09="">
        <div data-v-61537a09="" className="add-account">
          <h2 className="m-b-20">Add Account</h2>
          <form onSubmit={submitHandler} data-vv-scope="InserUserAccount" method="post">
            <div className="row">
              <div className="col-6">
                <h4 className="mb-4 col-12">Personal Detail</h4>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="username">Username:</label>
                      <input
                        type="text"
                        placeholder="username"
                        name="username"
                        className="form-control"
                        value={formData.username}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="password">User Password:</label>
                      <input
                        type="password"
                        placeholder="User Password"
                        name="password"
                        className="form-control"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="rpassword">Retype Password:</label>
                      <input
                        type="password"
                        placeholder="Retype Password"
                        name="rpassword"
                        className="form-control"
                        value={formData.rpassword}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="fullname">Full Name:</label>
                      <input
                        type="text"
                        placeholder="Full Name"
                        name="fullname"
                        className="form-control"
                        value={formData.fullname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="city">City:</label>
                      <input
                        type="text"
                        placeholder="City"
                        name="city"
                        className="form-control"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="phone">Phone:</label>
                      <input
                        type="text"
                        placeholder="Phone Number"
                        name="phone"
                        maxLength={15}
                        className="form-control"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <h4 className="mb-4 col-12">Account Detail</h4>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="accountType">Account Type:</label>
                      <select
                        name="accountType"
                        className="form-control"
                        value={formData.accountType}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Account Type</option>
                        <option value="admin">admin</option>
                        <option value="user">User</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="creditReference">Credit Reference:</label>
                      <input
                        type="text"
                        placeholder="Credit Reference"
                        name="creditReference"
                        className="form-control"
                        value={formData.creditReference}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <div className="form-group col-3 float-right">
                  <label htmlFor="mpassword">Transaction Password:</label>
                  <input
                    placeholder="Transaction Password"
                    type="password"
                    name="mpassword"
                    className="form-control"
                    value={formData.mpassword}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
            {error && (
              <div className="row mt-4">
                <div className="col-12">
                  <div className="alert alert-danger">{error}</div>
                </div>
              </div>
            )}
            <div className="row m-t-20">
              <div className="col-md-12">
                <div className="float-right">
                  <button type="submit" className="btn btn-submit">
                    Create Account
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
