import React, { useContext, useEffect, useState } from 'react'
import Admin from './Admin'
import { UserContext } from '../context/UserContext';
import axios from '../utils/axiosConfig';

export default function GameReport() {

    const { user, updateUser, setAlertt } = useContext(UserContext);
    const [creatorUsers, setCreatorUsers] = useState([]);

    const fetchUser = async () => {
        try {
            const response = await axios.post('/auth/users', { username: user.username });
            setCreatorUsers(response.data);
        } catch (error) {
            console.error(error);
        }
        console.log(creatorUsers); // This will log the initial state of creatorUsers, not the updated state.
    };

    // Non-async useEffect with internal async function
    useEffect(() => {
        const loadData = async () => {
            await fetchUser();
        };
        loadData();
    }, []);

    return (
        <div>
            <Admin></Admin>

            <div style={{ padding: 15 }} className="table-responsive report-table">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="user-name">
                                <div>Username</div>
                            </th>{" "}
                            <th className="event-name">
                                <div>Nation</div>
                            </th>{" "}
                            <th className="text-right bet-user-rate">
                                <div>Rate</div>
                            </th>{" "}
                            {/**/}{" "}
                            <th className="text-right bet-amount">
                                <div>Amount</div>
                            </th>{" "}
                            <th className="text-right bet-amount">
                                <div>Win</div>
                            </th>{" "}
                            <th className="bet-date">
                                <div>Date</div>
                            </th>{" "}


                        </tr>
                    </thead>{" "}
                    <tbody>








                        {creatorUsers.map((userr, index) => (
                            <>
                                {userr.BetHistory && userr.BetHistory.length > 0 ? ( // Check if currentBet exists and is not empty
                                    <>
                                        {userr.BetHistory.map((bet, betIndex) => (
                                            <tr className="back-border">
                                            <td className="user-name">
                                                <span>{userr.username}</span>
                                            </td>{" "}
                                            <td className="event-name">
                                                <div>{bet.remark}</div>
                                            </td>{" "}
                                            <td className="text-right bet-user-rate">
                                                <div>{bet.rate}</div>
                                            </td>{" "}
                                            {/**/}{" "}
                                            <td className="text-right bet-amount">
                                                <div>{bet.amount}</div>
                                            </td>{" "}
                                            <td className="text-right bet-amount">
                                                <div className="text-success">{bet.remark.endsWith('-W') ? bet.amount*bet.rate :<span role="cell" className="text-danger">{bet.amount}</span>
                                            }</div>
                                            </td>{" "}
                                            <td className="bet-date">
                                                <div>{new Date(bet.date).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</div>
                                            </td>{" "}
                                        </tr>
                                        ))}
                                    </>
                                ) : (
                                    <p></p>
                                )}
                            </>
                        ))}








                        
                        {/**/}
                    </tbody>
                </table>
            </div>{" "}
            {/**/}
        </div>

    )
}
