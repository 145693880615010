import React, { useEffect, useState } from 'react'
import '../Style/Home.css'
import userImg from '../Images/shield-user-line.png'
import MyNumber from './MyNumber'
import Header from './Header'
import Loader from './Loader'
import { Link, Navigate } from 'react-router-dom'
import axios from '../utils/axiosConfig'; // Import your axios configuration


export default function Home() {
    const [kalyan, setkalyan] = useState("0");
    const [kalyanMorning, setkalyanMorning] = useState("0");
    const [kalyanNight, setkalyanNight] = useState("0");
    const [dataArr, setDataArr] = useState([]); // Use state to store data array
    const [loading, setloading] = useState(true);



    // DEVELOPMENT_URL=http://localhost:4000
    // REACT_APP_ENVIRONMENT=production
    // PRODUCTION_URL=https://example.com


    const fetchdata = async () => {
        // let url = 'https://matkaserver.onrender.com/';
        // try {
        //     const response = await fetch(url + 'number');
        //     const html = await response.text();
        //     extractDiv(html);
        // } catch (error) {
        //     console.error('Error fetching the HTML:', error);
        // }



        try {
            const response = await axios.post('/auth/kalyan', {"Action":0}); // API endpoint for registration
            const responsee = await axios.post('/auth/kalyannight', {"Action":0}); // API endpoint for registration
            setkalyan(response.data)
            setkalyanNight(responsee.data)
            
           
            
          } catch (err) {
            setkalyan('none')
            setkalyanNight('none')
          } finally {
            setloading(false);
          }



    };

    function extractDiv(html) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const h8Divs = doc.querySelectorAll('.tkt-val div');

        const tempArr = [];
        h8Divs.forEach((div, index) => {
            const indexx = index + 1;
            if (indexx === 3 || indexx === 18 || indexx === 31) {
                const name = div.querySelector('h4').textContent;
                const id = div.querySelector('span').textContent;
                tempArr.push({ name, id });
            }
        });

        setDataArr(tempArr); // Update state with new data
    }

    // const setNumber = () => {
    //     dataArr.forEach(item => {
    //         switch (item.name) {
    //             case 'KALYAN MORNING':
    //                 setkalyanMorning(item.id);
    //                 break;
    //             case 'KALYAN':
    //                 setkalyan(item.id);
    //                 break;
    //             case 'KALYAN NIGHT':
    //                 setkalyanNight(item.id);
    //                 break;
    //             default:
    //                 break;
    //         }
    //     });

    //     setloading(false);
    // };

    useEffect(() => {
        fetchdata();
    }, []); // Fetch data only once on mount

    useEffect(() => {
        
    }, []); // Run setNumber when dataArr changes



    return (

        loading ? <Loader /> :
            <>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <title>MATKA BOSS</title>
                <meta
                    name="description"
                    content="Unlock the ultimate Satta Matka experience with DPBOSS Services. get accurate results, live updates on Kalyan Matka result, and expert tips for Matka experience."
                />
                <meta
                    name="keywords"
                    content="dpboss, dpboss network, goa matka, ડીપીબોસ, ಡಿಪಿಬೋಸ್, dp boss, dpboss matka, dpboss matka network, डीपी बॉस, dpboss net, d boss kalyan, dpboss. network, matka boss, dpboss matka result, goa matka result, dpb0ss, kullu matka, dpboss live, dp matka, dpboss net network, dpboss result, my dp boss, matka result live, డిపి బాస్, ಡಿಪಿ ಬಾಸ್ , ಡಿ ಬಾಸ್ ಮಟ್ಕಾ, dpboss matka channel, dpboss dpboss, kalyan main result, dpboss नेट अंदाज, dp boos matka com, my dpboss, dp boss net, https://dpboss.net, sabse tezz live result yahi milega, d boss matka, tara matka, new dpboss, dpbossss.services, https://dpboss.net/, dp boss net, dp market net, डीपी बॉस नेट वर्क, dpbossmatkacom, data matka com, dp satta, boss matka, 06:45 pm 07:45 pm jodi panel night time bazar, kalyan dp"
                />
                <meta
                    name="author"
                    content="DPBOSS | SATTA MATKA | KALYAN MATKA | MATKA RESULT | MATKA | SATTA"
                />
                <link rel="canonical" href="https://dpbossss.services" />
                <link rel="alternate" href="https://dpbossss.services/" hrefLang="en-in" />
                <meta
                    name="google-site-verification"
                    content="GXd4mOaZUG5EizveDSrgXfHa1rlCCRD0upJyFHNXITE"
                />
                <meta name="google" content="notranslate" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta content="yes" name="apple-mobile-web-app-capable" />
                <meta content="yes" name="apple-touch-fullscreen" />
                <meta name="Robots" content="index, follow" />
                <meta name="author" content="dpboss" />
                <meta name="copyright" content="dpboss net satta matka" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Satta Matka" />
                <meta property="og:description" content="SattaMatka" />
                <meta property="og:url" content="https://dpbossss.services/" />
                <link
                    rel="apple-touch-icon"
                    sizes="57x57"
                    href="https://dpbossss.services/newfev/apple-icon-57x57.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="60x60"
                    href="https://dpbossss.services/newfev/apple-icon-60x60.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="72x72"
                    href="https://dpbossss.services/newfev/apple-icon-72x72.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="76x76"
                    href="https://dpbossss.services/newfev/apple-icon-76x76.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="114x114"
                    href="https://dpbossss.services/newfev/apple-icon-114x114.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="120x120"
                    href="https://dpbossss.services/newfev/apple-icon-120x120.png"
                />
                <link rel="stylesheet" href="style.css" />

                <Header />

                <div
                    style={{
                        marginBottom: 5,
                        display: "flex",
                        padding: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 10,
                        border: "2px solid #ff182c",
                        boxShadow: "0 0 20px 0 rgb(0 0 0 / 40%)"
                    }}
                >
                    <p style={{ color: "black", display: "inline-block", fontSize: 16 }}>
                        !! Welcome to Matka Boss international !! Fast Result
                    </p>
                </div>
                <div className="f-pti">
                    <h3>Today Lucky Number</h3>
                    <div className="dflex">
                        <div
                            className="j52g4"
                            style={{ borderRight: "1px solid #ff0016", width: "40%" }}
                        >
                            <h4>Golden Ank</h4>
                            <p>3-8-0-5</p>
                        </div>
                        <div className="j52g4" style={{ width: "60%" }}>
                            <h4>Final Ank</h4>
                            <div className="amthltg">
                                <p>
                                    MILAN MORNING - 4<br />
                                    SRIDEVI - 6<br />
                                    KALYAN MORNING - 6<br />
                                    MADHURI - 6<br />
                                    SRIDEVI MORNING - ...
                                    <br />
                                    KARNATAKA DAY - 6<br />
                                    TIME BAZAR - 2<br />
                                    MILAN DAY - 2<br />
                                    KALYAN - 6<br />
                                    SRIDEVI NIGHT - 6<br />
                                    MADHURI NIGHT - 2<br />
                                    MILAN NIGHT - 4<br />
                                    RAJDHANI NIGHT - 8<br />
                                    MAIN BAZAR - ...
                                    <br />
                                    KALYAN NIGHT - 6<br />
                                    OLD MAIN MUMBAI - 6<br />
                                    MADHUR DAY - 4<br />
                                    MADHUR NIGHT - 0<br />{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="liv-rslt">
                    <h4>☔LIVE RESULT☔</h4>
                </div>
                <div className="tkt-val" style={{ borderColor: "#aa00c0", marginBottom: 2 }}>

                    {/* <div id='kalyanMorning'>
                        <Link to="/kalyanMorning">
                            <h4>KALYAN MORNING</h4>
                            <span id='KALYAN MORNING'>{kalyanMorning}</span>
                            <p>11:00 AM &nbsp;&nbsp; 12:02 PM</p>
                            <a href="jodi-chart-record/kalyan-morning.php" className="vl-clk gm-clk">
                                Jodi
                            </a>
                            <a
                                href="panel-chart-record/kalyan-morning.php"
                                className="vl-clk-2 gm-clk"
                            >
                                Panel
                            </a>
                        </Link>
                    </div> */}
                    <div style={{position:'relative'}} id='kalyan' className="">
                        <Link to="/kalyan">
                            <h4>KALYAN</h4>
                            <span id='KALYAN'>{kalyan}</span>
                            <p>04:05 PM &nbsp;&nbsp; 06:05 PM</p>
                            <a href="jodi-chart-record/kalyan.php" className="vl-clk gm-clk">
                                Jodi
                            </a>
                            <a href="panel-chart-record/kalyan.php" className="vl-clk-2 gm-clk">
                                Panel
                            </a>
                        </Link>
                    </div>
                    <div style={{position:'relative'}} id='kalyanNight' className="">
                        <Link to="/kalyanNight">
                            <h4>KALYAN NIGHT</h4>
                            <span id='KALYAN NIGHT'>{kalyanNight}</span>
                            <p>09:20 PM &nbsp;&nbsp; 11:20 PM</p>
                            <a href="jodi-chart-record/kalyan-night.php" className="vl-clk gm-clk">
                                Jodi
                            </a>
                            <a href="panel-chart-record/kalyan-night.php" className="vl-clk-2 gm-clk">
                                Panel
                            </a>
                        </Link>
                    </div>
                    <div style={{position:'relative'}} id='kalyanNight' className="">
                        <Link to="/kalyanNight">
                            <h4>RAJDHANI DAY</h4>
                            <span id='KALYAN NIGHT'>{}</span>
                            <p>09:20 PM &nbsp;&nbsp; 11:20 PM</p>
                            <a href="jodi-chart-record/kalyan-night.php" className="vl-clk gm-clk">
                                Jodi
                            </a>
                            <a href="panel-chart-record/kalyan-night.php" className="vl-clk-2 gm-clk">
                                Panel
                            </a>
                        </Link>
                    </div>
                    <div style={{position:'relative'}} id='kalyanNight' className="">
                        <Link to="/kalyanNight">
                            <h4>RAJDHANI NIGHT</h4>
                            <span id='KALYAN NIGHT'>{}</span>
                            <p>09:20 PM &nbsp;&nbsp; 11:20 PM</p>
                            <a href="jodi-chart-record/kalyan-night.php" className="vl-clk gm-clk">
                                Jodi
                            </a>
                            <a href="panel-chart-record/kalyan-night.php" className="vl-clk-2 gm-clk">
                                Panel
                            </a>
                        </Link>
                    </div>
                    <div style={{position:'relative'}} id='kalyanNight' className="">
                        <Link to="/kalyanNight">
                            <h4>MILAN DAY</h4>
                            <span id='KALYAN NIGHT'>{}</span>
                            <p>09:20 PM &nbsp;&nbsp; 11:20 PM</p>
                            <a href="jodi-chart-record/kalyan-night.php" className="vl-clk gm-clk">
                                Jodi
                            </a>
                            <a href="panel-chart-record/kalyan-night.php" className="vl-clk-2 gm-clk">
                                Panel
                            </a>
                        </Link>
                    </div>
                    <div style={{position:'relative'}} id='kalyanNight' className="">
                        <Link to="/kalyanNight">
                            <h4>MILAN NIGHT</h4>
                            <span id='KALYAN NIGHT'>{}</span>
                            <p>09:20 PM &nbsp;&nbsp; 11:20 PM</p>
                            <a href="jodi-chart-record/kalyan-night.php" className="vl-clk gm-clk">
                                Jodi
                            </a>
                            <a href="panel-chart-record/kalyan-night.php" className="vl-clk-2 gm-clk">
                                Panel
                            </a>
                        </Link>
                    </div>
                    <div style={{position:'relative'}} id='kalyanNight' className="">
                        <Link to="/kalyanNight">
                            <h4>TARA MUMBAI DAY</h4>
                            <span id='KALYAN NIGHT'>{}</span>
                            <p>09:20 PM &nbsp;&nbsp; 11:20 PM</p>
                            <a href="jodi-chart-record/kalyan-night.php" className="vl-clk gm-clk">
                                Jodi
                            </a>
                            <a href="panel-chart-record/kalyan-night.php" className="vl-clk-2 gm-clk">
                                Panel
                            </a>
                        </Link>
                    </div>
                    <div style={{position:'relative'}} id='kalyanNight' className="">
                        <Link to="/kalyanNight">
                            <h4>TARA MUMBAI NIGHT</h4>
                            <span id='KALYAN NIGHT'>{}</span>
                            <p>09:20 PM &nbsp;&nbsp; 11:20 PM</p>
                            <a href="jodi-chart-record/kalyan-night.php" className="vl-clk gm-clk">
                                Jodi
                            </a>
                            <a href="panel-chart-record/kalyan-night.php" className="vl-clk-2 gm-clk">
                                Panel
                            </a>
                        </Link>
                    </div>
                    <div style={{position:'relative'}} id='kalyanNight' className="">
                        <Link to="/kalyanNight">
                            <h4>TIME BAZAR</h4>
                            <span id='KALYAN NIGHT'>{}</span>
                            <p>09:20 PM &nbsp;&nbsp; 11:20 PM</p>
                            <a href="jodi-chart-record/kalyan-night.php" className="vl-clk gm-clk">
                                Jodi
                            </a>
                            <a href="panel-chart-record/kalyan-night.php" className="vl-clk-2 gm-clk">
                                Panel
                            </a>
                        </Link>
                    </div>
                    
                </div>
                {/* <div className="tkt-val" style={{ borderColor: "#aa00c0", marginBottom: 2 }}>
                <div>
                    <h4>MAIN MUMBAI NIGHT</h4>
                    <span>470-10-370</span>
                    <p>09:10 PM &nbsp;&nbsp; 11:10 PM</p>
                    <a
                        href="jodi-chart-record/main-mumbai-night.php"
                        className="vl-clk gm-clk"
                    >
                        Jodi
                    </a>
                    <a
                        href="panel-chart-record/main-mumbai-night.php"
                        className="vl-clk-2 gm-clk"
                    >
                        Panel
                    </a>
                </div>
            </div> */}
                <div className="oc-fg">
                    <div className="sta-div sta-1">
                        <h6>SATTA MATKA JODI CHART</h6>
                        {/* <a href="https://dpbossss.services/jodi-chart-record/kalyan-morning.php">
                            Kalyan Morning Chart
                        </a> */}
                        <a href="https://dpbossss.services/jodi-chart-record/kalyan.php">
                            Kalyan Chart
                        </a>
                        <a href="https://dpbossss.services/jodi-chart-record/kalyan-night.php">
                            Kalyan Night Chart
                        </a>
                        {/* <a href="https://dpbossss.services/jodi-chart-record/old-main-mumbai.php">
                            Old Main Mumbai Chart
                        </a> */}
                    </div>
                    <div className="sta-div sta-1">
                        <h6>MATKA PANEL CHART</h6>
                        {/* <a href="https://dpbossss.services/panel-chart-record/kalyan-morning.php">
                            Kalyan Morning Panel Chart
                        </a> */}
                        <a href="https://dpbossss.services/panel-chart-record/kalyan.php">
                            Kalyan Penal Chart
                        </a>
                        <a href="https://dpbossss.services/panel-chart-record/kalyan-night.php">
                            Kalyan Night Penal Chart
                        </a>
                        {/* <a href="https://dpbossss.services/panel-chart-record/old-main-mumbai.php">
                            Old Main Mumbai Panel Chart
                        </a> */}
                    </div>
                </div>
                <div className="dis12">
                    <h6>-:DISCLAIMER:-</h6>
                    <p>
                        Visiting this site and browsing it is strictly recommended at your own
                        risk. Every information available here is only according to informational
                        purpose and based on astrology and number calculations. We are no
                        associated or affiliated with any illegal Matka business. We make sure we
                        follow all rules and regulations of the regions where you are accessing
                        the website. There are also chances that the website may be banned in your
                        area and after that if you are using it, you are solely dependable and
                        responsible for any damage, loss or legal action taken.
                        <br />
                        If you are the one who does not like our disclaimer it is advised that you
                        leave our website immediately. Copying of any information/contents posted
                        on the website is strictly prohibited and against the law.
                    </p>
                </div>


            </>

    )
}
