import React, { createContext, useState, useEffect } from 'react';

// Create a context for the user
export const UserContext = createContext();

// Create a provider component


export const UserProvider = ({ children }) => {
  // Initialize user state from localStorage
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  // Initialize alert state from localStorage or default
  const [alertt, setAlertt] = useState(() => {
    const savedAlert = localStorage.getItem('alertt');
    return savedAlert ? JSON.parse(savedAlert).allertBollean : true;
  });

  const updateUser = (newUserData) => {
    setUser(newUserData);
  };

  // Sync user state with localStorage
  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user'); // Clear user data if null
    }
  }, [user]);

  // Sync alertt state with localStorage
  useEffect(() => {
    localStorage.setItem('alertt', JSON.stringify({ allertBollean: alertt }));
  }, [alertt]);

  return (
    <UserContext.Provider value={{ user, setUser, updateUser, alertt, setAlertt }}>
      {children}
    </UserContext.Provider>
  );
};

