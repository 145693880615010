import { useState , useContext } from 'react';
import axios from '../utils/axiosConfig'; // Import your axios configuration
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const useRegister = () => {
  const [loading, setLoading] = useState(false); 
  const [er, setEr] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { user , alertt, setAlertt,} = useContext(UserContext);

  const register = async (userData) => {
    setLoading(true);
    setEr(null);
    setSuccess(false);

    try {
      const response = await axios.post('/auth/register', userData); // API endpoint for registration
      setSuccess(true); // Set success to true if registration is successful
      console.log('Registration successful:', response.data);
      setAlertt(true)
      navigate('/admin2');
    } catch (err) {
      setEr(err.response?.data?.message || 'Registration failed'); // Set error message
      console.error('Registration failed:', err);
    } finally {
      setLoading(false);
    }
  };

  return { register, loading, er, success };
};

export default useRegister;
