import React, { useContext } from 'react'
import Header from './Header'
import { UserContext } from '../context/UserContext';

export default function Mybets() {
    const { setUser, user, setAlertt } = useContext(UserContext);


    console.log(user);


    return (
        <div className="mt-2 table-responsive">
            <Header></Header>
            <table style={{marginBottom:100}} role="table" className="table table-bordered table-striped">
                <thead>
                    <tr role="row">
                        <th colSpan={1} role="columnheader" className="report-date">
                            Date
                        </th>
                        <th colSpan={1} role="columnheader" className="report-sr text-end">
                            Sr no
                        </th>
                        <th colSpan={1} role="columnheader" className="report-amount text-end">
                            Credit
                        </th>
                        <th colSpan={1} role="columnheader" className="report-amount text-end">
                            Debit
                        </th>
                        <th colSpan={1} role="columnheader" className="report-amount text-end">
                            Pts
                        </th>
                        <th colSpan={1} role="columnheader">
                            Remark
                        </th>
                    </tr>
                </thead>
                <tbody role="rowgroup">
                    <tr role="row">
                        <td role="cell" className="report-date">
                            
                        </td>
                        <td role="cell" className="report-sr text-end">
                            0
                        </td>
                        <td role="cell" className="report-amount text-end">
                            <span role="cell" className="">
                                0
                            </span>
                        </td>
                        <td role="cell" className="report-amount text-end">
                            <span role="cell" className="" />
                        </td>
                        <td role="cell" className="report-amount text-end">
                            <span role="cell" className="">
                                0
                            </span>
                        </td>
                        <td role="cell">Opening Pts</td>
                    </tr>


                    {user && user.BetHistory && user.BetHistory.length > 0 ? (
                        user.BetHistory.map((elem, index) => (
                            <tr role="row">
                                <td role="cell" className="report-date">
                                    {new Intl.DateTimeFormat('en-IN', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                        hour12: true, 
                                        timeZone: 'Asia/Kolkata',
                                    }).format(new Date(elem.date))}
                                </td>
                                <td role="cell" className="report-sr text-end">
                                    {index + 1}
                                </td>
                                <td role="cell" className="report-amount text-end">
                                    <span role="cell" className="text-success">
                                    {elem.remark.endsWith('-W') ? elem.amount : ''}
                                    </span>
                                </td>
                                <td role="cell" className="report-amount text-end">
                                    <span role="cell" className="text-danger">{elem.remark.endsWith('-L') ? elem.amount : ''}</span>
                                </td>
                                <td role="cell" className="report-amount text-end">
                                    <span role="cell" className="text-success">
                                    {elem.remark.endsWith('-W') ? elem.amount*elem.rate : <span role="cell" className="text-danger">
                                        {elem.amount}
                                    </span>}
                                    </span>
                                </td>
                                <td role="cell">{elem.remark}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6">No Bets Found</td>
                        </tr>
                    )}






                </tbody>
            </table>
        </div>

    );
}
