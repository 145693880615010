import React, { useContext } from 'react'
import Header from './Header'
import { UserContext } from '../context/UserContext';

export default function CurrentBet() {
    const { setUser, user, setAlertt } = useContext(UserContext);


    console.log(user);


    return (
        <div>

            <Header style={{ width: "100vw" }} />


            <div style={{ padding: "20px" }} className="report-form">
                
                <div className="row row5 mt-2 justify-content-between align-items-center">
                    <div className="col-lg-2 col-5">
                        <div className="mb-2 input-group position-relative">
                            <span className="me-2">Show</span>
                            <select className="form-select">
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </select>
                            <span className="ms-2">Entries</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 text-left col-7">
                        <div>
                            Total Bets: <span className="me-2">2</span> Total Amount:{" "}
                            <span className="me-2">200</span>
                        </div>
                    </div>
                    <div className="col-lg-2 col-5">
                        <div className="mb-2 input-group position-relative">
                            <span className="me-2">Search:</span>
                            <input
                                type="search"
                                className="form-control"
                                placeholder="2 records..."
                                defaultValue=""
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-2 table-responsive">
                    <table role="table" className="table table-bordered table-striped">
                        <thead>
                            <tr role="row">
                                <th colSpan={1} role="columnheader">
                                    Event Name
                                </th>
                                <th colSpan={1} role="columnheader">
                                    Nation
                                </th>
                                <th
                                    colSpan={1}
                                    role="columnheader"
                                    className="report-amount text-end"
                                >
                                    User Rate
                                </th>
                                <th
                                    colSpan={1}
                                    role="columnheader"
                                    className="report-amount text-end"
                                >
                                    Amount
                                </th>
                                <th colSpan={1} role="columnheader" className="report-date">
                                    Place Date
                                </th>
                                <th colSpan={1} role="columnheader" className="report-action">
                                    <div className="text-end">
                                        <div className="form-check form-check-inline">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                title="Toggle All Current Page Rows Selected"
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup">

                            {user && user.currentBet && user.currentBet.length > 0 ? (
                                user.currentBet.map((elem, index) => (
                                    <tr key={index} role="row" className="back">
                                        <td role="cell">{elem.event}{" "}{elem.remark.split('/')[1]}</td>
                                        <td role="cell">
                                            <span role="cell">{elem.number}{" "}{elem.nation}</span>
                                        </td>
                                        <td role="cell" className="report-amount text-end">
                                            {elem.rate}
                                        </td>
                                        <td role="cell" className="report-amount text-end">
                                            {elem.amount}
                                        </td>
                                        <td role="cell" className="report-date">
                                            {new Intl.DateTimeFormat('en-IN', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: true, // 12-hour format with AM/PM
                                                timeZone: 'Asia/Kolkata',
                                            }).format(new Date(elem.date))}
                                        </td>
                                        <td role="cell" className="report-action">
                                            <div className="text-end">
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        title="Toggle Row Selected"
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">No Current Bets Found</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>
                <div className="custom-pagination mt-2">
                    <div disabled="">First</div>
                    <div disabled="">Previous</div>
                    <div disabled="">Next</div>
                    <div disabled="">Last</div>
                    <div>
                        <span className="me-2">
                            Page <b>1 of 1</b>
                        </span>
                        <span className="me-2">| Go to Page</span>
                        <input className="form-control" type="number" defaultValue={1} />
                    </div>
                </div>
            </div>
        </div>
    );
}
